import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  MenuItem,
  Typography
} from '@mui/material';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { FilterAlt, Home as HomeIcon } from '@mui/icons-material';
import { MechanicIcon } from '../../../common/Icons';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled';
import { useAppContext } from '../../../../contexts/AppContext';
import { checkPermissionsAndRedirect, getFormErrorMessage, verifyFieldsAreFilled } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { exportSaldoEPIPessoaToPDF } from './configs/utils';
import { getSaldoEpisPessoa } from './configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { listaUsuariosFilter } from '../../management/people/configs/functions';
import { CustomToolbarReports } from '../../../common/CustomToolbarReports';
import { useQuery } from '@tanstack/react-query';
import { PaginatedUserAutocomplete } from '../../../common/PaginatedUserAutocomplete';

export function RelatorioSaldoPessoa() {
  const { exibirAlerta } = useCommonItems();
  const { dadosUsuario, getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

  const [pessoas, setPessoas] = useState([]);
  const [pagePessoas, setPagePessoas] = useState(0);
  const [isLoadingPessoas, setIsLoadingPessoas] = useState(false);
  const [hasMorePessoas, setHasMorePessoas] = useState(true);

  const [dataSaldoEPIs, setDataSaldoEPIs] = useState([]);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const {
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const columns = useMemo(() => [
    { field: 'produto.ca', headerName: 'CA', minWidth: 150, flex: 0.5, valueGetter: (params) => params.row.produto?.ca },
    { field: 'produto.nome', headerName: 'Produto', minWidth: 360, flex: 2, valueGetter: (params) => params.row.produto?.nome },
    { field: 'produto.cod_externo', headerName: 'Cód. Produto', minWidth: 120, flex: 0.5, valueGetter: (params) => params.row.produto?.cod_externo },
    {
      field: 'qtd_disponivel',
      headerName: 'Quantidade',
      minWidth: 120,
      flex: 0.5,
      valueGetter: (params) => params.row.qtd_disponivel ?? params.row.quantidade_maxima
    },
    // {
    //   field: 'ja_retirou',
    //   headerName: 'Já Retirou',
    //   minWidth: 120,
    //   flex: 0.5,
    //   valueGetter: (params) => params.row.qtd_disponivel ? true : false,
    //   renderCell: (params) => params.value ? 'Sim' : 'Não'
    // },
    {
      field: 'produto.validade_ca',
      headerName: 'Validade CA',
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => params.row.produto?.validade_ca ? dayjs(params.row.produto.validade_ca).format('DD/MM/YYYY') : ''
    },
    { field: 'produto.vida_util', headerName: 'Vida Útil', minWidth: 120, flex: 0.5, valueGetter: (params) => params.row.produto?.vida_util },
    { field: 'produto.categorias.nome', headerName: 'Categoria', minWidth: 200, flex: 1, valueGetter: (params) => params.row.produto?.categorias?.nome },
    { field: 'produto.unidade_medida.nome', headerName: 'Unidade', minWidth: 100, flex: 0.5, valueGetter: (params) => params.row.produto?.unidade_medida?.nome },
  ], []);

  const { data: empresasData, error: listaEmpresasError, isLoading: isLoadingEmpresasData, isPending } = useQuery({
    queryKey: ['listaEmpresas'],
    queryFn: async () => {
      const response = await listaEmpresas();
      const empresasData = response?.data.data;

      let defaultEmpresaId = null;
      const selectedEmpresaIdSession = getEmpresaIdSession();
      if (selectedEmpresaIdSession) {
        defaultEmpresaId = selectedEmpresaIdSession;
      } else {
        defaultEmpresaId = empresasData[0].id;
        setEmpresaIdSession(empresasData[0].id);
      }

      setSelectedEmpresaId(defaultEmpresaId);

      const defaultValues = {
        id_empresa: defaultEmpresaId,
        matricula_pessoa: null
      };
      reset(defaultValues);

      return response;
    },
    refetchOnWindowFocus: false,
  });

  const empresas = empresasData?.data.data || [];
  const isLoadingEmpresas = isLoadingEmpresasData || isPending;

  async function loadReportData(id_empresa, matriculaPessoa) {
    try {
      setIsLoading(true);
      const res = await getSaldoEpisPessoa({ id_empresa, matricula: matriculaPessoa });
      setDataSaldoEPIs(res.data.saldo_epis);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error === "O usuário não possui permissões") {
        exibirAlerta('Atenção', error.response.data.error, 'warning');
      } else {
        exibirAlerta('Ops', 'Ocorreu um erro ao carregar dados do relatório.', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsSearched(true);
      loadReportData(data.id_empresa, data.matricula_pessoa);
    } catch (error) {
      exibirAlerta('Erro', 'Erro ao carregar dados do relatório', 'error');
      setIsSearched(false);
    }
  };

  const listaColaboradores = useCallback(async (currentPage = 0, initialPageValue = false, filterValue = "") => {
    if (!selectedEmpresaId) return;

    if (initialPageValue) {
      setPessoas([]);
      setPagePessoas(0);
      setHasMorePessoas(true);
    }

    try {
      setIsLoadingPessoas(true);

      const filters = {
        id_empresa: selectedEmpresaId,
        limit: 10,
        offset: (initialPageValue ? 0 : currentPage) * 10,
      };

      // if nome is only number, then it's matricula
      if (filterValue && !isNaN(filterValue)) {
        filters.matricula = filterValue;
        delete filters.nome;
      } else {
        filters.nome = filterValue;
        delete filters.matricula;
      }

      const response = await listaUsuariosFilter(filters);
      const newOptions = response.data.data;

      setPessoas((prevOptions) => [...prevOptions, ...newOptions]);
      setHasMorePessoas(newOptions.length > 0);
      setPagePessoas((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Erro ao carregar colaboradores:", error);
    } finally {
      setIsLoadingPessoas(false);
    }
  }, [selectedEmpresaId]);

  useEffect(() => {
    const requiredPermissionsView = ["admin", "admin_relatorio"];
    checkPermissionsAndRedirect(requiredPermissionsView);

    return () => {
      reset();
    }
  }, [reset]);

  useEffect(() => {
    listaColaboradores(0, true);
  }, [listaColaboradores, selectedEmpresaId]);

  useEffect(() => {
    if (listaEmpresasError) {
      exibirAlerta('Erro', 'Erro ao carregar empresas', 'error');
    }
  }, [exibirAlerta, listaEmpresasError])

  function exportDataToPDF(visibleColumns, reportName) {
    try {
      if (!getValues('matricula_pessoa')) {
        return exibirAlerta('Ops', 'Selecione um colaborador para exportar', 'warning');
      }

      exportSaldoEPIPessoaToPDF({
        visibleColumns,
        reportName,
        colaborador: pessoas.find(pessoa => pessoa.matricula === getValues('matricula_pessoa')),
        tableData: dataSaldoEPIs,
        selectEmpresa: empresas.find(empresa => empresa.id === Number(selectedEmpresaId)),
        dadosUsuario
      })
    } catch (error) {
      exibirAlerta('Ops', 'Erro ao exportar dados para PDF', 'error');
      console.log(error);
    }
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Breadcrumbs>
          <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
          <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
          <LinkNavegacao to='/epis/relatorios'>Relatórios</LinkNavegacao>
          <Typography variant='span'>Relatório de Saldo de EPI's por Pessoa</Typography>
        </Breadcrumbs>
        <PageTitle icon={<MechanicIcon fontSize='large' />} title="Relatório de Saldo de EPI's por Pessoa" />
      </Grid>

      <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container item xs={12} md={12} spacing={3}>
          <Grid item xs={12} md={5}>
            <SelectEmpresaFilter
              empresas={empresas}
              id_empresas={empresas}
              defaultValue={selectedEmpresaId}
              onChangeValue={(value) => {
                setValue('id_empresa', value, { shouldDirty: true });
                setIsSearched(false);
                setSelectedEmpresaId(value);
                setEmpresaIdSession(value);
                setValue('matricula_pessoa', null, { shouldDirty: true })
              }}
              textHelper={false}
              isLoading={isLoadingEmpresas}
            />
          </Grid>

          <Grid item xs={12} md={5}>
            <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
              <PaginatedUserAutocomplete
                name="matricula_pessoa"
                control={control}
                rules={{ required: true }}
                label="Colaborador"
                disabled={!selectedEmpresaId}
                options={pessoas}
                getOptionLabel={(option) => {
                  // verify if the user exists in pessoas
                  const pessoa = pessoas.find(p => p.matricula === option);
                  return pessoa ? `${pessoa.matricula} - ${pessoa.nome}` : '';
                }}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option.matricula} disabled={option.status === 0}>
                    {option.matricula} - {option.nome} {option.status === 0 ? '- (Inativo)' : ''}
                  </MenuItem>
                )}
                onChange={(event, value) => {
                  setIsSearched(false);
                  setValue('matricula_pessoa', value ? value.matricula : null, { shouldDirty: true });

                  if (value) {
                    clearErrors('matricula_pessoa')
                  } else {
                    listaColaboradores(0, true);
                  }
                }}
                onInput={(event) => {
                  setPessoas([]);
                  setPagePessoas(0);
                  setHasMorePessoas(true);
                  const filterValue = event.target.value ?? "";
                  listaColaboradores(0, true, filterValue);
                }}
                loadOptionsFn={() => listaColaboradores(pagePessoas, false, "")}
                hasMore={hasMorePessoas}
                helperText={getFormErrorMessage(errors, 'matricula_pessoa')}
                error={!!errors.matricula_pessoa}
                loading={isLoadingPessoas}
                textFieldSize="small"
                textFieldStyle={{ backgroundColor: '#fff' }}
              />
            </TooltipSelectDisabled>
          </Grid>

          <Grid item xs={12} md={2}>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              disabled={isLoading || Object.keys(errors).length > 0 || !verifyFieldsAreFilled([getValues('matricula_pessoa')])}
              startIcon={isLoading ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <FilterAlt />}
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          height: dataSaldoEPIs.length > 0 ? "auto" : 400,
          minHeight: 400,
          width: '100%',
          '& .MuiDataGrid-columnHeaders, .MuiDataGrid-toolbarContainer': {
            backgroundColor: '#d8d8d8',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderRadius: 0
          },
        }}>
          <DataGrid
            getRowId={(row) => row.produto.id}
            rows={dataSaldoEPIs}
            columns={columns}
            loading={isLoading}
            pageSizeOptions={[10, 20, 50, 100]}
            paginationMode="client"
            sx={{
              backgroundColor: '#fff',
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold !important',
                overflow: 'visible !important',
              }
            }}
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            slots={{
              toolbar: (props) =>
                <CustomToolbarReports
                  reportName="relatorio_saldo_epi_colaborador"
                  pdfExport={exportDataToPDF}
                  props={props}
                />,
              loadingOverlay: LinearProgress,
              noRowsOverlay: () => (
                <Box style={{ display: "flex", width: '100%', textAlign: 'center', height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                  {isSearched ?
                    <Typography variant='h6' color='textSecondary'>Nenhum registro encontrado para o filtro selecionado</Typography>
                    :
                    <Typography variant='h6' color='textSecondary'>Selecione um colaborador e o período que deseja filtrar</Typography>
                  }
                </Box>
              )
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}