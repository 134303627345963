import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  Collapse,
  Card,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import { useAppContext } from '../../../../../contexts/AppContext';
import { TableFilterButtons } from '../../../../common/TableFilterButtons';

export function UsersTableFilter({ isOpenFilters, isLoadingList, empresas }) {
  const { setEmpresaIdSession } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams()

  const nome = searchParams.get('nome');
  const matricula = searchParams.get('matricula');
  const email = searchParams.get('email');
  const id_empresa = searchParams.get('id_empresa');
  const perPage = searchParams.get('perPage');

  const { handleSubmit, reset, control } = useForm({
    defaultValues: {
      nome: nome ? nome : matricula ? matricula : '',
      email: email ?? '',
      matricula: matricula ?? '',
      id_empresa: id_empresa ?? '',
    },
  });

  function handleSubmitFilter(data) {
    for (const key in data) {
      // remove campos vazios
      if (data[key] === '') {
        delete data[key];
      }
    }

    // if nome is only number, then it's matricula
    if (data.nome && !isNaN(data.nome)) {
      data.matricula = data.nome;
      delete data.nome;
    } else {
      delete data.matricula;
    }

    const queryFilters = {
      ...data,
      page: '1',
      perPage: perPage ?? '10'
    }

    // fill url params with filters
    setSearchParams(queryFilters);

    if (data.id_empresa) {
      setEmpresaIdSession(data.id_empresa);
    }
  }

  useEffect(() => {
    reset({
      nome: nome ?? '',
      email: email ?? '',
      matricula: matricula ?? '',
      id_empresa: id_empresa ?? '',
    });
  }, [id_empresa, nome, email, matricula, reset])

  return (
    <Grid item xs={12} component="form" onSubmit={handleSubmit(handleSubmitFilter)}>
      <Collapse in={isOpenFilters}>
        <Card variant="outlined" sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Controller
                name="id_empresa"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    select
                    label="Empresa"
                    fullWidth
                    size="small"
                    {...field}
                  >
                    {empresas.length > 0 && empresas.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nomeFantasia}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="nome"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="Nome ou Matrícula do Colaborador"
                    fullWidth
                    size="small"
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="Email de Usuário"
                    fullWidth
                    size="small"
                    type='text'
                    {...field}
                  />
                )}
              />
            </Grid>

            <TableFilterButtons
              isLoading={isLoadingList}
              handleClearFilters={() => {
                setSearchParams((state) => {
                  state.delete('nome')
                  state.delete('email')
                  state.delete('matricula')
                  // state.delete('id_empresa')
                  state.set('page', '1')

                  return state
                })

                reset({
                  nome: '',
                  email: '',
                  matricula: '',
                  id_empresa: id_empresa ?? '',
                });
              }}
            />
          </Grid>
        </Card>
      </Collapse>
    </Grid>
  );
}
