import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { GetApp } from '@mui/icons-material';
import { downloadBase64AsJpg, getIconAuthType, getImageSrc } from '../configs/utils';

export function AuthCaptureCell({ tipoAutenticacao, nomeFuncionario, faceBiometricPhoto }) {
  return tipoAutenticacao === 'biometria' && faceBiometricPhoto ? (
    <Tooltip title={
      <Box>
        <img src={getImageSrc(faceBiometricPhoto)} alt={nomeFuncionario} style={{ maxWidth: 150, borderRadius: 4 }} />
        <Box sx={{ position: "absolute", top: 10, right: 10 }}>
          <IconButton
            variant='contained'
            size="small"
            onClick={(e) => downloadBase64AsJpg(e, getImageSrc(faceBiometricPhoto), nomeFuncionario)}
            sx={{
              bgcolor: "#fff",
              borderRadius: "50%",
              "&:hover": {
                bgcolor: "#efefef"
              }
            }}
            color='primary'
            aria-label="Download"
          >
            <GetApp fontSize="small" />
          </IconButton>
        </Box>
      </Box >
    } arrow sx={{ cursor: "pointer" }}>
      <Stack spacing={1} direction="row" alignItems="center">
        {getIconAuthType(tipoAutenticacao)}
        <Typography>{tipoAutenticacao}</Typography>
      </Stack>
    </Tooltip>
  ) : (
    <Stack spacing={1} direction="row" alignItems="center">
      {getIconAuthType(tipoAutenticacao)}
      <Typography>{tipoAutenticacao}</Typography>
    </Stack>
  );
}
