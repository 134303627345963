import { TableRow, TableCell, Skeleton, Stack } from '@mui/material';

export function SkeletonTableRow({ numCells, numRows = 5, firstCellIsIcon = false, numActions = 2 }) {
  const numCellsTextRender = firstCellIsIcon ? numCells - 2 : numCells - 1;

  return (
    <>
      {Array(numRows).fill(0).map((_, index) => (
        <TableRow key={index}>
          {firstCellIsIcon &&
            <TableCell align="center">
              <Stack direction="row" spacing={1.2} justifyContent={'center'} >
                <Skeleton variant="circular" width={30} height={30} />
              </Stack>
            </TableCell>
          }

          {Array(numCellsTextRender).fill(0).map((_, index) => (
            <TableCell key={index}>
              <Skeleton variant="text" width={"100%"} />
            </TableCell>
          ))}

          <TableCell align='right' sx={{ minWidth: 110 }}>
            <Stack direction="row" spacing={1.2} justifyContent={'flex-end'} sx={{ p: 0.5 }}>
              {Array.from({ length: numActions }).map((_, index) => (
                <Skeleton key={index} variant="circular" width={30} height={30} />
              ))}
            </Stack>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}