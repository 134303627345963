export async function validateRequiredFields(getValues, setError, clearErrors, requiredFieldsStep) {
  const errors = requiredFieldsStep.reduce((acc, field) => {
    if (!getValues(field) || (Array.isArray(getValues(field)) && getValues(field).length === 0)) {
      acc[field] = 'Campo obrigatório';
    }
    return acc;
  }, {});

  if (Object.keys(errors).length > 0) {
    Object.keys(errors).forEach((key) => {
      setError(key, { type: 'manual', message: errors[key] });
    });

    return false;
  }

  clearErrors();
  return true;
}