import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { Home, Restore, Search } from '@mui/icons-material';
import { ManualIcon } from '../../../common/Icons';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { useAppContext } from '../../../../contexts/AppContext';
import { checkPermissionsAndRedirect, getFormErrorMessage, hasPermission, verifyFieldsAreFilled } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled';
import { listaUsuariosFilter } from '../../management/people/configs/functions';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { DevolucaoForm } from './DevolucaoForm';
import { getRetiradas, listaProdutos } from './configs/functions';
import { PaginatedUserAutocomplete } from '../../../common/PaginatedUserAutocomplete';
import DateRangePicker from '../../../common/DateRangePicker';
import { AutocompleteProducts } from '../../../common/AutocompleteProducts';

const RetiradaRow = ({ data, handleOpenEditForm }) => {
  return (
    <TableRow
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      }}
    >
      <TableCell sx={{ flex: 2, border: 0 }}>{data.codigo}</TableCell>
      <TableCell sx={{ flex: 2, border: 0 }}>{data.pessoa.nome} - {data.pessoa.matricula}</TableCell>
      <TableCell sx={{ flex: 3, border: 0 }}>{data.quantidadeProduto}x - {data.produto.nome}</TableCell>
      <TableCell sx={{ flex: 2, border: 0 }}>{data.equipamento.nome}</TableCell>
      <TableCell sx={{ flex: 2, border: 0 }}>{data.createdAt}</TableCell>
      <TableCell sx={{ flex: 1, border: 0 }} align='right'>
        {hasPermission(["admin", "admin_retiradas", "update_retiradas"]) &&
          <Tooltip title={data.isDevolucao ? 'Devolução já realizada' : 'Realizar Devolução'} arrow>
            <span>
              <IconButton
                aria-label="Abrir Devolução"
                onClick={() => handleOpenEditForm(data)}
                disabled={data.isDevolucao}
                sx={{
                  filter: data.isDevolucao ? 'brightness(0.9) grayscale(1)' : 'none',
                  '&:hover': {
                    filter: data.isDevolucao ? 'brightness(0.5) grayscale(1)' : 'brightness(0.8)',
                  }
                }}
              >
                <ManualIcon fontSize='large' />
              </IconButton>
            </span>
          </Tooltip>
        }
      </TableCell>
    </TableRow>
  )
}

export function DevolucaoRetiradas() {
  const { exibirAlerta } = useCommonItems();
  const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

  // estados para controle de paginacao
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [numTotalItems, setNumTotalItems] = useState(0);

  const initialDataInicio = dayjs().subtract(1, 'month');
  const initialDataFim = dayjs();

  const [dateRange, setDateRange] = useState([initialDataInicio, initialDataFim]);

  const [retiradas, setRetiradas] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [produtos, setProdutos] = useState([]);

  const [colaboradores, setColaboradores] = useState([]);
  const [pagePessoas, setPagePessoas] = useState(0);
  const [isLoadingPessoas, setIsLoadingPessoas] = useState(false);
  const [hasMorePessoas, setHasMorePessoas] = useState(true);

  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCompanyData, setIsLoadingCompanyData] = useState(false);

  const [openForm, setOpenForm] = useState(false);

  const {
    handleSubmit, setValue, getValues, clearErrors, reset, control,
    formState: { errors },
  } = useForm();

  const getMatriculaFromId = (id_pessoa, colaboradores) => {
    return id_pessoa === 'all' ? null : colaboradores.find(c => c.id === id_pessoa)?.matricula;
  };

  const listaColaboradores = async (currentPage = 0, initialPageValue = false, filterValue = "") => {
    if (!selectedEmpresaId) return;

    if (initialPageValue) {
      setColaboradores([]);
      setPagePessoas(0);
      setHasMorePessoas(true);
    }

    try {
      setIsLoadingPessoas(true);

      const filters = {
        id_empresa: selectedEmpresaId,
        limit: 10,
        offset: (initialPageValue ? 0 : currentPage) * 10,
      };

      // if nome is only number, then it's matricula
      if (filterValue && !isNaN(filterValue)) {
        filters.matricula = filterValue;
        delete filters.nome;
      } else {
        filters.nome = filterValue;
        delete filters.matricula;
      }

      const response = await listaUsuariosFilter(filters);
      const newOptions = response.data.data;

      setColaboradores((prevOptions) => [...prevOptions, ...newOptions]);
      setHasMorePessoas(newOptions.length > 0);
      setPagePessoas((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Erro ao carregar colaboradores:", error);
    } finally {
      setIsLoadingPessoas(false);
    }
  }

  const carregaProdutos = async (empresaId) => {
    if (!empresaId) return;

    try {
      const res = await listaProdutos({ id_empresa: empresaId });
      setProdutos(res?.data.data);
    } catch (error) {
      exibirAlerta('Erro ao carregar os Produtos', '', 'error');
    }
  }

  async function carregaRetiradas(id_empresa, data_inicio, data_fim, id_pessoa, id_produto) {
    if (!id_empresa) return;

    try {
      setIsLoading(true);

      const filter = {
        id_empresas: Array.isArray(id_empresa) ? id_empresa : [id_empresa],
        data_inicio: dayjs(data_inicio).format('YYYY-MM-DD'),
        data_fim: dayjs(data_fim).format('YYYY-MM-DD'),
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      };

      if (id_pessoa) {
        filter.matricula = getMatriculaFromId(id_pessoa, colaboradores);
      }

      if (id_produto && id_produto !== 'all') {
        filter.id_produto = id_produto;
      }

      const res = await getRetiradas(filter);
      setRetiradas(res.data.data);
      setNumTotalItems(res.data.numero_total);
    } catch (error) {
      console.error(error);
      exibirAlerta('Erro', 'Erro ao carregar Retiradas', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  function clearFilters() {
    const defaultValues = {
      id_empresa: getValues('id_empresa'),
      id_pessoa: 'all',
      id_produto: 'all',
      data_inicio: initialDataInicio,
      data_fim: initialDataFim,
    };
    reset(defaultValues);
    carregaRetiradas(defaultValues.id_empresa, defaultValues.data_inicio, defaultValues.data_fim, defaultValues.id_pessoa, defaultValues.id_produto);
  }

  const onSubmit = async (data) => {
    carregaRetiradas(data.id_empresa, data.data_inicio, data.data_fim, data.id_pessoa, data.id_produto);
  };

  function handleEdit(item) {
    setSelectedItem(item);
    setOpenForm(true);
  }

  async function getDataEmpresa(empresaId) {
    setIsLoadingCompanyData(true);
    await listaColaboradores(0, true);
    await carregaProdutos(empresaId);
    setIsLoadingCompanyData(false);
  }

  useEffect(() => {
    const requiredPermissionsView = ["admin", "admin_retiradas", "list_retiradas"];
    checkPermissionsAndRedirect(requiredPermissionsView);

    let defaultEmpresaId = null;
    const selectedEmpresaIdSession = getEmpresaIdSession();
    if (selectedEmpresaIdSession) {
      defaultEmpresaId = selectedEmpresaIdSession;
      setSelectedEmpresaId(selectedEmpresaIdSession);
    }

    listaEmpresas().then((response => {
      const empresasData = response?.data.data;
      setEmpresas(empresasData);

      if (!selectedEmpresaIdSession) {
        defaultEmpresaId = empresasData[0].id;
        setSelectedEmpresaId(defaultEmpresaId);
        setEmpresaIdSession(defaultEmpresaId);
      }

      // getDataEmpresa(defaultEmpresaId);

      const defaultValues = {
        id_empresa: defaultEmpresaId,
        id_pessoa: 'all',
        id_produto: 'all',
        data_inicio: initialDataInicio,
        data_fim: initialDataFim,
      };
      reset(defaultValues);
      carregaRetiradas(defaultValues.id_empresa, defaultValues.data_inicio, defaultValues.data_fim, defaultValues.id_pessoa, defaultValues.id_produto);
    }));

    return () => {
      reset();
    }
  }, []);

  useEffect(() => {
    if (selectedEmpresaId) {
      getDataEmpresa(selectedEmpresaId);
    }
  }, [selectedEmpresaId]);

  useEffect(() => {
    if (openForm) return;

    const id_empresa = selectedEmpresaId;
    const id_pessoa = getValues('id_pessoa');
    const id_produto = getValues('id_produto');
    const data_inicio = getValues('data_inicio')
    const data_fim = getValues('data_fim')

    if (!id_empresa || !data_inicio || !data_fim) {
      return;
    }

    carregaRetiradas(id_empresa, data_inicio, data_fim, id_pessoa, id_produto);
  }, [page, rowsPerPage, openForm]);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs>
          <LinkNavegacao to='/'><Home fontSize='small' /></LinkNavegacao>
          <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
          <LinkNavegacao to='/epis/retiradas'>Retiradas</LinkNavegacao>
          <LinkNavegacao to='/epis/retiradas/devolucoes'>Devoluções</LinkNavegacao>
          <Typography variant='span'>Registrar Devolução</Typography>
        </Breadcrumbs>

        <PageTitle icon={<Restore fontSize='large' />} title='Registrar Devolução' />
      </Grid>

      <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container item xs={12} md={12} spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <SelectEmpresaFilter
              empresas={empresas}
              id_empresas={empresas}
              defaultValue={selectedEmpresaId}
              onChangeValue={(value) => {
                setSelectedEmpresaId(value);
                setEmpresaIdSession(value);
                setValue('id_empresa', value, { shouldDirty: true });
                setValue('id_pessoa', '', { shouldDirty: true });
              }}
              textHelper={false}
            />
          </Grid>

          {colaboradores &&
            <Grid item xs={12} md={6} lg={4}>
              <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                <PaginatedUserAutocomplete
                  name="id_pessoa"
                  control={control}
                  label="Colaborador"
                  disabled={!selectedEmpresaId}
                  options={colaboradores.length > 0 ? [{ id: 'all', matricula: '', nome: 'Todos os colaboradores', status: 1 }, ...colaboradores] : []}
                  getOptionLabel={(option) => {
                    const pessoa = colaboradores.find(p => p.id === option);
                    return option === 'all' ? 'Todos os colaboradores' : pessoa ? `${pessoa.matricula} - ${pessoa.nome}` : '';
                  }}
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.id} disabled={option.status === 0}>
                      {option.id !== 'all' && `${option.matricula} -`} {option.nome} {option.status === 0 ? '- (Inativo)' : ''}
                    </MenuItem>
                  )}
                  onChange={(e, value) => {
                    setValue('id_pessoa', value ? value.id : null, { shouldDirty: true });
                    if (value) {
                      clearErrors('id_pessoa')
                    } else {
                      listaColaboradores(0, true);
                    }
                  }}
                  loadOptionsFn={() => listaColaboradores(pagePessoas, false, "")}
                  onInput={(event) => {
                    setColaboradores([]);
                    setPagePessoas(0);
                    setHasMorePessoas(true);
                    const filterValue = event.target.value ?? "";
                    listaColaboradores(0, true, filterValue);
                  }}
                  loading={isLoadingPessoas}
                  error={!!errors.id_pessoa}
                  helperText={getFormErrorMessage(errors, 'id_pessoa')}
                  hasMore={hasMorePessoas}
                  textFieldSize="small"
                  textFieldStyle={{ backgroundColor: '#fff' }}
                />
              </TooltipSelectDisabled>
            </Grid>
          }

          <Grid item xs={12} md={6} lg={4}>
            <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
              <AutocompleteProducts
                name='id_produto'
                control={control}
                rules={{ required: true }}
                produtos={produtos}
                onChange={(e, value) => {
                  setValue('id_produto', value ? value.id : null, { shouldDirty: true });
                  if (value) clearErrors('id_produto');
                }}
                errors={errors}
                isLoading={isLoadingCompanyData}
                disabled={isLoadingCompanyData || !selectedEmpresaId}
                helperText={getFormErrorMessage(errors, 'id_produto')}
              />
            </TooltipSelectDisabled>
          </Grid>
        </Grid>

        <Grid container item xs={12} md={12} spacing={3}>

          <Grid item xs={12} md={4}>
            <DateRangePicker
              value={dateRange}
              bgColor='#fff'
              onChange={(value) => {
                setDateRange(value);
                const [start, end] = value;
                setValue('data_inicio', start ? dayjs(start).format('YYYY-MM-DD') : '');
                setValue('data_fim', end ? dayjs(end).format('YYYY-MM-DD') : '');
              }}
              onReset={() => {
                setDateRange([null, null]);
                setValue('data_inicio', '');
                setValue('data_fim', '');
              }}
              hasError={errors.data_inicio || errors.data_fim}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} justifyContent={'space-between'}>
              <Button
                fullWidth
                type='submit'
                color='primary'
                variant='contained'
                disabled={
                  isLoading ||
                  Object.keys(errors).length > 0 ||
                  !verifyFieldsAreFilled([getValues('id_empresa'), getValues('data_inicio'), getValues('data_fim')])
                }
                startIcon={isLoading ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <Search />}
              >
                Filtrar
              </Button>

              <Button
                fullWidth
                variant="outlined"
                startIcon={<Restore />}
                onClick={clearFilters}
              >
                Limpar Filtros
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="Retiradas">
            <TableHead>
              <TableRow sx={{ display: 'flex' }}>
                <TableCell sx={{ flex: 2 }}>Código</TableCell>
                <TableCell sx={{ flex: 2 }}>Colaborador</TableCell>
                <TableCell sx={{ flex: 3 }}>Produto</TableCell>
                <TableCell sx={{ flex: 2 }}>Equipamento</TableCell>
                <TableCell sx={{ flex: 2 }}>Data</TableCell>
                <TableCell sx={{ flex: 1 }} />
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? <LoadingTableRow />
                : retiradas && retiradas?.length > 0
                  ? retiradas.map((item) =>
                    <RetiradaRow key={item.id} data={item} handleOpenEditForm={handleEdit} />
                  )
                  : <EmptyTableRow colSpan={7} />
              }
            </TableBody>
          </Table>

          <CustomTablePagination
            numTotalItems={numTotalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </TableContainer>
      </Grid>

      {selectedItem &&
        <DevolucaoForm
          open={openForm}
          setOpen={setOpenForm}
          selectedItem={selectedItem}
          selectedEmpresaId={selectedEmpresaId}
        />
      }
    </Grid>
  );
}