import { createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { carregaModuloEmpresas } from '../components/screens/management/settings/configs/functions';
import { useAppContext } from './AppContext';

const LOCAL_STORAGE_KEY = '@STW:modulosEmpresa';

const ModuloContext = createContext();

export const ModuleProvider = ({ children }) => {
  const { token: userToken } = useAppContext();

  const { data: modulosEmpresas } = useQuery({
    queryKey: ['modulosEmpresas'],
    queryFn: async () => {
      const response = await carregaModuloEmpresas();

      const newModulos = response?.data?.data || [];

      const modulosMap = newModulos.reduce((acc, moduloEmpresa) => {
        const { id } = moduloEmpresa.empresa;
        const { titulo } = moduloEmpresa.modulo;

        if (!acc[id]) {
          acc[id] = {};
        }

        acc[id][titulo] = moduloEmpresa.modoIntegracao;
        return acc;
      }, {});

      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(modulosMap));

      return modulosMap;
    },
    enabled: !!userToken,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const loadModulesFromLocalStorage = () => {
    try {
      const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
      return storedData ? JSON.parse(storedData) : null;
    } catch {
      return null;
    }
  };

  const verifyIntegrationMode = (moduleName, selectedEmpresaId) => {
    if (!selectedEmpresaId) return false;

    const storedModulosEmpresas = modulosEmpresas || loadModulesFromLocalStorage();

    if (!storedModulosEmpresas) return false;

    const empresaModulos = storedModulosEmpresas[selectedEmpresaId];
    return empresaModulos ? empresaModulos[moduleName] !== "recebe" : true;
  };

  function getEmpresasFilteredByModule(moduleName, empresas, shouldFilter = true) {
    // o shouldFilter é utilizado pois, nas telas de edição é necessário exibir todas as empresas
    // mas para o cadastro, é necessário filtrar as empresas que tem não podem enviar dados
    if (!shouldFilter) return empresas;
    if (!empresas || !modulosEmpresas) return [];

    const filteredEmpresas = empresas.filter((empresa) => {
      if (modulosEmpresas[empresa.id] && modulosEmpresas[empresa.id][moduleName]) {
        return modulosEmpresas[empresa.id][moduleName] !== "recebe";
      }
      return true;
    });

    return filteredEmpresas;
  }

  return (
    <ModuloContext.Provider
      value={{
        modulosEmpresas,
        verifyIntegrationMode,
        getEmpresasFilteredByModule
      }}
    >
      {children}
    </ModuloContext.Provider>
  );
};

export const useModuleContext = () => useContext(ModuloContext);