import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { FilterAlt, Home as HomeIcon } from '@mui/icons-material';
import { VendingMachineIcon } from '../../../common/Icons';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { useAppContext } from '../../../../contexts/AppContext';
import { checkPermissionsAndRedirect, getFormErrorMessage, verifyFieldsAreFilled } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { exportSupplyMachineToPDF } from './configs/utils';
import { getAbastecimentoData } from './configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled';
import { CustomToolbarReports } from '../../../common/CustomToolbarReports';
import { carregaEquipamentos } from '../equipments/configs/functions';
import DateRangePicker from '../../../common/DateRangePicker';
import { validateRequiredFields } from '../../../../utils/validate-required-fields';
import { formatDateTime, parseDateTime } from '../../../../utils/format-date';

export function AbastecimentoMaquina() {
  const { exibirAlerta, exibirDialog, handleCloseDialog } = useCommonItems();
  const { dadosUsuario, getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

  // estados para controle de paginacao
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(20);
  const [numTotalItems, setNumTotalItems] = useState(0);

  const [supplyMachine, setDataSupplyMachine] = useState([]);
  const [equipamentos, setEquipamentos] = useState([]);

  const initialDataInicio = dayjs().subtract(1, 'month')
  const initialDataFim = dayjs()

  const [dateRange, setDateRange] = useState([initialDataInicio, initialDataFim]);

  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const {
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm();

  const columns = useMemo(() => [
    { field: 'maquina', headerName: 'Máquina', minWidth: 200, flex: 1 },
    { field: 'motor', headerName: 'Motor', minWidth: 80, flex: 0.5 },
    { field: 'produtoCa', headerName: 'CA', minWidth: 120, flex: 0.5 },
    { field: 'produtoNome', headerName: 'Produto', minWidth: 280, flex: 0.5 },
    { field: 'produtoCodExterno', headerName: 'Cód. Produto', minWidth: 120, flex: 0.5 },
    { field: 'reposição', headerName: 'Quantidade', minWidth: 90, flex: 0.5 },
    { field: 'classificacaoMovimentacao', headerName: 'Classificação', minWidth: 120, flex: 0.5 },
    { field: 'abastecedor', headerName: 'Abastecedor', minWidth: 140, flex: 0.8 },
    { field: 'area', headerName: 'Área', minWidth: 200, flex: 1 },
    { field: 'setor', headerName: 'Setor', minWidth: 200, flex: 1 },
    { field: 'endereco', headerName: 'Endereço', minWidth: 200, flex: 1 },
    { field: 'str', headerName: 'STR', minWidth: 90, flex: 0.5 },
    {
      field: 'data',
      headerName: 'Data',
      minWidth: 200,
      flex: 1,
      type: 'dateTime',
      valueGetter: (params) => parseDateTime(params.row?.data),
      renderCell: (params) => formatDateTime(params.row?.data, "DD/MM/YYYY HH:mm:ss"),
    }
  ], []);

  const { data: empresasData, error: listaEmpresasError, isLoading: isLoadingEmpresasData, isPending } = useQuery({
    queryKey: ['listaEmpresas'],
    queryFn: async () => {
      const response = await listaEmpresas();
      const empresasData = response?.data.data;

      let defaultEmpresaId = null;
      const selectedEmpresaIdSession = getEmpresaIdSession();
      if (selectedEmpresaIdSession) {
        defaultEmpresaId = selectedEmpresaIdSession;
      } else {
        defaultEmpresaId = empresasData[0].id;
        setEmpresaIdSession(empresasData[0].id);
      }

      setSelectedEmpresaId(defaultEmpresaId);
      // getDataEmpresa(defaultEmpresaId);

      const defaultValues = {
        id_empresas: defaultEmpresaId,
        id_equipamento: '',
        data_inicio: initialDataInicio,
        data_fim: initialDataFim,
      };
      reset(defaultValues);

      loadReportData(defaultEmpresaId, '', initialDataInicio, initialDataFim);

      return response;
    },
    refetchOnWindowFocus: false,
  });

  const empresas = empresasData?.data.data || [];
  const isLoadingEmpresas = isLoadingEmpresasData || isPending;

  const listaEquipamentos = async (empresaId) => {
    try {
      exibirDialog('Carregando Equipamento...');
      const filterAllMachines = { tipo: "maquina", id_empresas: Array.isArray(empresaId) ? empresaId : [empresaId] };
      const response = await carregaEquipamentos(filterAllMachines);
      setEquipamentos(response.data.data);
    } catch (error) {
      exibirAlerta('Erro ao carregar os Equipamentos', '', 'error');
    } finally {
      handleCloseDialog();
    }
  }

  async function loadReportData(id_empresas, id_equipamento, data_inicio, data_fim) {
    try {
      setIsLoading(true);

      data_inicio = dayjs(data_inicio).format('YYYY-MM-DD');
      data_fim = dayjs(data_fim).format('YYYY-MM-DD');

      const filter = {
        id_empresas: [id_empresas],
        data_inicio,
        data_fim,
        // limit: rowsPerPage,
        // offset: page * rowsPerPage,
      };

      if (id_equipamento) filter.id_equipamento = id_equipamento;

      const res = await getAbastecimentoData(filter);
      setDataSupplyMachine(res.data.movimentacoes);
      setNumTotalItems(res.data.numero_total);
    } catch (error) {
      console.log(error);
      exibirAlerta('Ops', 'Ocorreu um erro ao carregar dados do relatório.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      const isFilled = await validateRequiredFields(getValues, setError, clearErrors, ['id_empresas', 'data_inicio', 'data_fim']);

      if (!isFilled) throw new Error('Preencha os campos obrigatórios');

      setIsSearched(true);
      loadReportData(data.id_empresas, data.id_equipamento, data.data_inicio, data.data_fim);
    } catch (error) {
      exibirAlerta('Erro', 'Erro ao carregar dados do relatório', 'error');
      setIsSearched(false);
    }
  };

  function getDataEmpresa(empresaId) {
    listaEquipamentos(empresaId);
  }

  useEffect(() => {
    const requiredPermissionsView = ["admin", "admin_relatorio"];
    checkPermissionsAndRedirect(requiredPermissionsView);

    return () => {
      reset();
    }
  }, [reset]);

  useEffect(() => {
    if (selectedEmpresaId) {
      getDataEmpresa(selectedEmpresaId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmpresaId]);

  useEffect(() => {
    const id_empresas = selectedEmpresaId;
    const id_equipamento = getValues('id_equipamento');
    const data_inicio = getValues('data_inicio')
    const data_fim = getValues('data_fim')

    if (!id_empresas || !data_inicio || !data_fim) {
      return;
    }

    loadReportData(id_empresas, id_equipamento, data_inicio, data_fim);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listaEmpresasError) {
      exibirAlerta('Erro', 'Erro ao carregar empresas', 'error');
    }
  }, [exibirAlerta, listaEmpresasError])

  function exportDataToPDF(visibleColumns, reportName) {
    try {
      if (!getValues('id_empresas')) {
        return exibirAlerta('Ops', 'Selecione ao menos empresa', 'warning');
      }

      // if date range is not selected
      if (!getValues('data_inicio') || !getValues('data_fim')) {
        return exibirAlerta('Ops', 'Selecione um período para exportar', 'warning');
      }

      exportSupplyMachineToPDF({
        tableData: supplyMachine,
        visibleColumns,
        reportName,
        data_inicio: getValues('data_inicio'),
        data_fim: getValues('data_fim'),
        selectEmpresa: empresas.find(empresa => empresa.id === Number(selectedEmpresaId)),
        dadosUsuario
      })
    } catch (error) {
      exibirAlerta('Ops', 'Erro ao exportar dados para PDF', 'error');
      console.log(error);
    }
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Breadcrumbs>
          <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
          <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
          <LinkNavegacao to='/epis/relatorios'>Relatórios</LinkNavegacao>
          <Typography variant='span'>Abastecimento de Máquinas</Typography>
        </Breadcrumbs>
        <PageTitle icon={<VendingMachineIcon fontSize='large' />} title="Abastecimento de Máquinas" />
      </Grid>

      <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container item xs={12} md={12} spacing={3}>
          <Grid item xs={12} md={6}>
            <SelectEmpresaFilter
              empresas={empresas}
              id_empresas={empresas}
              defaultValue={selectedEmpresaId}
              onChangeValue={(value) => {
                setIsSearched(false);
                setSelectedEmpresaId(value);
                setEmpresaIdSession(value);
                setValue('id_empresas', value, { shouldDirty: true });
                setValue('id_equipamento', '', { shouldDirty: true });
              }}
              textHelper={false}
              isLoading={isLoadingEmpresas}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
              <Controller
                name='id_equipamento'
                control={control}
                rules={{ required: false }}
                defaultValue={''}
                render={({ field: { ref, onChange, ...field } }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label="Máquina"
                    disabled={!selectedEmpresaId}
                    onChange={(e) => {
                      setIsSearched(false);
                      setValue('id_equipamento', e.target.value, { shouldDirty: true })
                      if (e.target.value) {
                        clearErrors("id_equipamento");
                      }
                    }}
                    error={!!errors.id_equipamento}
                    helperText={getFormErrorMessage(errors, 'id_equipamento')}
                    size='small'
                    InputProps={{ style: { backgroundColor: '#fff' } }}
                  >
                    {equipamentos.length > 0 ? (
                      [
                        ...equipamentos.filter(option => option.status !== 0).map(option => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.nome} - {option.tag_identificacao} ({option.qtd_motores} motores)
                          </MenuItem>
                        )),
                        ...equipamentos.filter(option => option.status === 0).map(option => (
                          <MenuItem key={option.id} value={option.id} disabled>
                            {option.nome} - {option.tag_identificacao} ({option.qtd_motores} motores) - (Inativo)
                          </MenuItem>
                        ))
                      ]
                    ) : (
                      <MenuItem disabled>Nenhum disponível</MenuItem>
                    )}
                  </TextField>
                )}
              />
            </TooltipSelectDisabled>
          </Grid>
        </Grid>

        <Grid container item xs={12} md={12} spacing={3}>
          <Grid item xs={12} md={4}>
            <DateRangePicker
              value={dateRange}
              bgColor='#fff'
              onChange={(value) => {
                setDateRange(value);
                const [start, end] = value;
                setValue('data_inicio', start ? dayjs(start).format('YYYY-MM-DD') : '');
                setValue('data_fim', end ? dayjs(end).format('YYYY-MM-DD') : '');
                setIsSearched(false);
              }}
              onReset={() => {
                setDateRange([null, null]);
                setValue('data_inicio', '');
                setValue('data_fim', '');
                setIsSearched(false);
              }}
              hasError={errors.data_inicio || errors.data_fim}
            />
          </Grid>

          <Grid item xs={12} md={1}>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              disabled={
                isLoading ||
                Object.keys(errors).length > 0 ||
                !verifyFieldsAreFilled([getValues('id_empresas')])
              }
              startIcon={isLoading ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <FilterAlt />}
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          height: supplyMachine.length > 0 ? "auto" : 400,
          minHeight: 400,
          width: '100%',
          '& .MuiDataGrid-columnHeaders, .MuiDataGrid-toolbarContainer': {
            backgroundColor: '#d8d8d8',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderRadius: 0
          },
        }}>
          <DataGrid
            getRowId={(row) => row.idMovimentacao}
            rows={supplyMachine}
            columns={columns}
            loading={isLoading}
            pageSizeOptions={[10, 20, 50, 100]}
            rowCount={numTotalItems}
            paginationMode="client"
            // paginationModel={{ pageSize: rowsPerPage, page: page }}
            // onPaginationModelChange={({ page, pageSize }) => {
            //   setPage(page);
            //   setRowsPerPage(pageSize);
            // }}
            sx={{
              backgroundColor: '#fff',
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold !important',
                overflow: 'visible !important',
              }
            }}
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            slots={{
              toolbar: (props) =>
                <CustomToolbarReports
                  reportName="relatorio_abastecimento_maquina"
                  pdfExport={exportDataToPDF}
                  props={props}
                />
              ,
              loadingOverlay: LinearProgress,
              noRowsOverlay: () => (
                <Box style={{ display: "flex", width: '100%', textAlign: 'center', height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                  {isSearched ?
                    <Typography variant='h6' color='textSecondary'>Nenhum registro encontrado para o filtro selecionado</Typography>
                    :
                    <Typography variant='h6' color='textSecondary'>Selecione uma empresa e o período que deseja filtrar</Typography>
                  }
                </Box>
              )
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}