import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  FormControl,
  TextField,
  MenuItem,
  Typography,
  Stack,
  Autocomplete,
  Divider,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material';
import { formulaCalRotationTimes } from '../../../../../utils';
import { useCommonItems } from '../../../../../contexts/CommonItensProvider';
import { HelpIcon } from '../../../../common/Icons';

export function ConfigEquipmentForm({ motores, setMotores, configEquipamento, produtos, produtosFilho, setProdutosFilho }) {
  const isEditAction = !!configEquipamento;

  const { exibirAlerta } = useCommonItems();

  // preenche um array com os valores de tempo de rotação, baseado na fórmula de cálculo
  const defaultRotationTimeValues = Array.from({ length: 256 }, (_, index) => formulaCalRotationTimes(index));

  const [idProdutoPai, setIdProdutoPai] = useState(0);
  const [idProduto, setIdProduto] = useState(0);
  const [qtdProduto, setQtdProduto] = useState("");
  const [capacidade, setCapacidade] = useState(1);
  const [multiplicador, setMultiplicador] = useState(1);
  const [numeroRotacoes, setNumeroRotacoes] = useState(1);
  const [tempoRotacao, setTempoRotacao] = useState(
    defaultRotationTimeValues.find(value => value === configEquipamento?.tempo_rotacao)
    || defaultRotationTimeValues[defaultRotationTimeValues.length / 2]
  );
  const [erroQtdProduto, setErroQtdProduto] = useState("");
  const [quantidadeFinal, setQuantidadeFinal] = useState(1);

  function verifyProductVariations(selectedProduct) {
    return selectedProduct && selectedProduct.filhos.length > 0;
  }

  useEffect(() => {
    const quantidadeCalculada = Math.floor((capacidade / numeroRotacoes) * multiplicador);
    setQuantidadeFinal(quantidadeCalculada);

    if (qtdProduto > quantidadeCalculada) {
      setErroQtdProduto("A quantidade de produto não pode exceder a capacidade.");
    } else {
      setErroQtdProduto("");
    }
  }, [capacidade, multiplicador, numeroRotacoes, qtdProduto]);

  useEffect(() => {
    if (!configEquipamento) return;

    const {
      qtd_produto = 0,
      multiplicador = 1,
      numero_rotacoes = 1,
      capacidade = 1,
      tempo_rotacao = 0,
      id_produto,
      mustSelectChild,
      id_produto_pai,
    } = configEquipamento;

    setQtdProduto(qtd_produto || 0);
    setMultiplicador(multiplicador || 1);
    setNumeroRotacoes(numero_rotacoes || 1);
    setCapacidade(capacidade || 1);
    setTempoRotacao(tempo_rotacao);

    const productId = id_produto;
    const selectedProductIsParent = produtos.find(prod => prod.id === productId);

    // caso de erro no submit em que a variação nao foi selecionada
    if (mustSelectChild && id_produto_pai) {
      setIdProduto("");
      setIdProdutoPai(id_produto_pai);
      const selectProduct = produtos.find(prod => prod.id === id_produto_pai);
      setProdutosFilho(selectProduct.filhos);
    }
    // nao possui id_produto_pai e não é um produto pai, então a ação é de edição
    // como o id_produto_pai não vem do banco, é necessário buscar o produto pai
    else if (productId && !id_produto_pai && !selectedProductIsParent) {
      let selectedVariation = null;
      produtos.forEach(prod => {
        const variation = prod.filhos.find(filho => filho.id === productId);
        if (variation) {
          selectedVariation = variation;
          return;
        }
      });

      if (selectedVariation) {
        setIdProduto(selectedVariation.id);
        const parentProduct = produtos.find(prod => prod.id === selectedVariation.idReferencia.produtoPrincipal.id);
        setIdProdutoPai(parentProduct.id);
        setProdutosFilho(parentProduct.filhos);
        return;
      }
    }
    else if (productId && id_produto_pai) {
      setIdProduto(productId);
      setIdProdutoPai(id_produto_pai);
      const parentProduct = produtos.find(prod => prod.id === id_produto_pai);
      setProdutosFilho(parentProduct.filhos);
    } else {
      // if doesn't have variations, idProduto is the selected product or none
      setProdutosFilho([]);
      setIdProdutoPai(configEquipamento.id_produto || "");
      setIdProduto(configEquipamento.id_produto || "");
    }
  }, [configEquipamento, produtos, setProdutosFilho]);

  function updateConfigEquipamento(updatedData) {
    const updatedMotors = [...motores];
    const motorIndex = updatedMotors.findIndex(motor => motor.numero_motor === configEquipamento.numero_motor);

    if (motorIndex !== -1) {
      updatedMotors[motorIndex] = {
        ...updatedMotors[motorIndex],
        is_dirty: true,
        ...updatedData
      };

      setMotores(updatedMotors);
    }
  }

  function handleQuantityChange(inputValue) {
    const value = parseInt(inputValue) || 0;
    if (value > quantidadeFinal) {
      setErroQtdProduto("A quantidade de produto não pode exceder a capacidade.");
    } else if (value < 0) {
      setErroQtdProduto("A quantidade de produto não pode ser menor que zero.");
    } else {
      setQtdProduto(value);
      updateConfigEquipamento({ qtd_produto: value });
      setErroQtdProduto("");
    }
  }

  function handleProductChange(productId) {
    setIdProdutoPai(productId);
    let produtosFilho = [];

    if (!productId) {
      updateConfigEquipamento({
        id_produto: null,
        id_produto_pai: null,
        status: 'livre',
        qtd_produto: 0,
        capacidade: 1,
        multiplicador: 1,
        numero_rotacoes: 1,
        tempo_rotacao: defaultRotationTimeValues[defaultRotationTimeValues.length / 2]
      });

      setIdProdutoPai("");
      setIdProduto("");
      setProdutosFilho(produtosFilho);

      setNumeroRotacoes(1);
      setMultiplicador(1);
      setCapacidade(1);
      setTempoRotacao(defaultRotationTimeValues[defaultRotationTimeValues.length / 2]);
      setQtdProduto(0);

      exibirAlerta('Alerta', 'Ao remover o produto, todos os dados do slot são resetados.', 'warning');
      return;
    }

    const selectedProduct = produtos.find(prod => prod.id === productId);
    const hasVariations = verifyProductVariations(selectedProduct);

    // if selected produto array filhos has length > 0, fill produtosFilhos
    if (hasVariations) {
      setIdProduto("");
      produtosFilho = selectedProduct.filhos;

      // if selectedProduct.filhos has length === 1, set idProduto with the only variation
      if (selectedProduct.filhos.length === 1) {
        handleProductVariationChange(selectedProduct.filhos[0].id);
      } else {
        updateConfigEquipamento({ id_produto: null, status: 'livre', mustSelectChild: true, id_produto_pai: productId });
      }
    } else {
      // if doesn't have variations, idProduto is the selected product
      updateConfigEquipamento({ id_produto: productId, status: 'ocupado', mustSelectChild: false, id_produto_pai: null });
    }

    setProdutosFilho(produtosFilho);
  }

  function handleProductVariationChange(variationId) {
    setIdProduto(variationId);
    const statusOcupado = variationId ? 'ocupado' : 'livre';
    updateConfigEquipamento({ id_produto: variationId, status: statusOcupado, mustSelectChild: false, id_produto_pai: idProdutoPai });
  }

  return (
    <>
      {configEquipamento ?
        <Box fullWidth maxWidth={'sm'}>
          {isEditAction
            ? <Typography variant="h6" gutterBottom>Editando Configuração de Motor: <strong>{configEquipamento.numero_motor}</strong></Typography>
            : <Typography variant="h6" gutterBottom>Cadastro de Motor</Typography>
          }

          <Box>
            <Typography sx={{ mb: 2 }}>
              {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar um motor.'}
            </Typography>

            <Box sx={{ backgroundColor: "#fafafa" }}>
              <FormControl variant="standard" fullWidth>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid container item xs={12} md={12} spacing={2}>
                    <Grid item xs={12} md={produtosFilho.length > 0 ? 6 : 12}>
                      <Autocomplete
                        options={produtos || []}
                        value={produtos.find((option) => option.id === idProdutoPai) || null}
                        onChange={(event, newValue) => handleProductChange(newValue ? newValue.id : '')}
                        getOptionLabel={(option) => {
                          const produto = produtos.find(p => p.id === option.id);
                          return option.id && produto ?
                            (produto.ca ? `CA: ${produto.ca} - ` : '') + produto.nome : '';
                        }}
                        renderOption={(props, option) => (
                          <MenuItem {...props} key={option.id} disabled={option.status === 0}>
                            {option.ca && `CA: ${option.ca} - `}{option.nome} {option.status === 0 ? '- (Inativo)' : ''}
                          </MenuItem>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Produto"
                            fullWidth
                            sx={{ mt: 1 }}
                          />
                        )}
                        noOptionsText="Nenhum produto encontrado"
                      />
                    </Grid>

                    {produtosFilho.length > 0 &&
                      <Grid item xs={12} md={6}>
                        <TextField
                          select
                          name='id_produto_filho'
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Tamanho/Variação"
                          value={idProduto}
                          onChange={(e) => handleProductVariationChange(e.target.value)}
                        >
                          {(produtosFilho && produtosFilho.length > 0) ? (
                            [
                              ...produtosFilho.filter(option => option.status !== 0).map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.variacao.tipo} - Cód: {option.cod_externo}
                                </MenuItem>
                              )),
                              ...produtosFilho.filter(option => option.status === 0).map(option => (
                                <MenuItem key={option.id} value={option.id} disabled>
                                  {option.variacao.tipo} - Cód: {option.cod_externo} - (Inativo)
                                </MenuItem>
                              ))
                            ]
                          ) : (
                            <MenuItem disabled>Nenhuma variação cadastrada.</MenuItem>
                          )}
                        </TextField>
                      </Grid>
                    }
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      margin='dense'
                      label='Estoque do Produto'
                      type='number'
                      name='qtd_produto'
                      fullWidth
                      value={qtdProduto}
                      error={!!erroQtdProduto}
                      helperText={erroQtdProduto || "Quantidade de itens total no motor."}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(e) => handleQuantityChange(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      disabled
                      margin='dense'
                      label="Capacidade máxima Estoque"
                      type='number'
                      name='qtd_produto'
                      fullWidth
                      value={quantidadeFinal}
                      helperText="Capacidade total que o Motor poderá armazenar"
                      onChange={() => { }}
                      InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="(Capacidade Máx. Motor / Nº de Rotações) * Multiplicador">
                              <IconButton edge="end">
                                <HelpIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider sx={{ my: 1 }} />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={defaultRotationTimeValues}
                      value={tempoRotacao}
                      onChange={(event, newValue) => {
                        setTempoRotacao(newValue)
                        updateConfigEquipamento({ tempo_rotacao: newValue });
                      }}
                      disableClearable
                      getOptionLabel={(option) => `${option} ms`}
                      noOptionsText="Nenhum valor correspondente"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tempo de rotação"
                          fullWidth
                          sx={{ mt: 1 }}
                          helperText="Valores em milissegundos (1600 a 3500ms)"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      margin='dense'
                      label='Capacidade Máxima do Motor'
                      helperText="Quantidade máxima de itens que o motor pode armazenar."
                      type='number'
                      inputProps={{ min: 1 }}
                      name='capacidade'
                      fullWidth
                      value={capacidade}
                      onChange={(e) => {
                        const value = parseInt(e.target.value) || 1;
                        setCapacidade(value);
                        updateConfigEquipamento({ capacidade: value });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      margin='dense'
                      label='Numero de voltas por Produto'
                      type='number'
                      inputProps={{ min: 1 }}
                      name='numero_rotacoes'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={numeroRotacoes}
                      helperText="Quantidade de voltas que o motor deve dar para descarregar o produto."
                      onChange={(e) => {
                        const value = parseInt(e.target.value) || 1;
                        setNumeroRotacoes(value);
                        updateConfigEquipamento({ numero_rotacoes: value });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      margin='dense'
                      label='Multiplicador'
                      type='number'
                      inputProps={{ min: 1 }}
                      name='multiplicador'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={multiplicador}
                      helperText="Caso o produto esteja agrupado, informe a quantidade de itens por caixa."
                      onChange={(e) => {
                        const value = parseInt(e.target.value) || 1;
                        setMultiplicador(value);
                        updateConfigEquipamento({ multiplicador: value });
                      }}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Box>
          </Box>
        </Box>
        : <Stack direction="column" justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%', paddingBottom: 5 }}>
          <Typography variant="h6" gutterBottom>Selecione um motor para configurar</Typography>
        </Stack>
      }
    </>
  );
}