import { useEffect, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  Stack,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { useCommonItems } from '../../contexts/CommonItensProvider';
import { validarSenha } from '../screens/management/companies/configs/functions';
import { fillInputErrors, getFormErrorMessage } from '../../configs/functions';
import { extractErrorMessage } from '../../utils/extract-error-message';

export function PasswordConfirmationDialog({ dialogRef }) {
  const { exibirAlerta } = useCommonItems();

  const [isOpen, setIsOpen] = useState(false);
  const [passwordHidden, setPasswordHidden] = useState(true);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm(
    {
      defaultValues: {
        password: '',
      }
    }
  );

  const [resolvePromise, setResolvePromise] = useState(null);

  // useImperativeHandle -> expoe a função openPasswordConfirmation para ser chamada de fora do componente
  useImperativeHandle(dialogRef, () => ({
    // abre o dialog e retorna uma promise pendente que será resolvida ao confirmar a senha
    openPasswordConfirmation() {
      setIsOpen(true);
      //  armazena a função de resolução da promise para ser chamada no callback de sucesso
      return new Promise((resolve) => setResolvePromise(() => resolve));
    },
  }));

  const successCallback = () => {
    setIsOpen(false);
    reset();
    // chama a funcao de resolução da promise passando true, indicando o sucesso
    if (resolvePromise) resolvePromise(true);
  };

  const { mutateAsync: validarSenhaFn, isPending } = useMutation({
    mutationFn: (senha) => validarSenha(senha),
  });

  async function handleFormSubmit(data) {
    try {
      const response = await validarSenhaFn(data.senha);
      if (!response) throw new Error('Erro ao validar senha');
      exibirAlerta('Sucesso', 'Senha validada com sucesso.', 'success');
      successCallback();
    } catch (error) {
      const errorMessage = extractErrorMessage(error, 'Senha incorreta. Tente novamente');
      exibirAlerta('Ops', errorMessage, 'error');

      if (error.response) {
        fillInputErrors(error, setError);
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      reset({ password: '' });
    }
  }, [isOpen, reset]);

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth='sm'
      disableEscapeKeyDown
    >
      <DialogTitle>
        Confirmação de Senha
      </DialogTitle>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogContent dividers>
          <DialogContentText pb={3}>
            <Typography variant="body1" gutterBottom>
              Para continuar, por favor, confirme sua senha.
            </Typography>

          </DialogContentText>

          <Stack
            direction="column"
            width={'100%'}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <TextField
              autoFocus
              fullWidth
              label="Senha"
              type={passwordHidden ? 'password' : 'text'}
              autoComplete='off'
              {...register("senha", { required: true })}
              error={!!errors.senha}
              helperText={getFormErrorMessage(errors, 'senha')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      arrow
                      placement="top"
                      title={passwordHidden ? 'Mostrar Senha' : 'Ocultar Senha'}
                    >
                      <IconButton
                        edge="end"
                        onClick={() => setPasswordHidden((prev) => !prev)}
                      >
                        {passwordHidden
                          ? <Visibility color='primary' />
                          : <VisibilityOff color='primary' />
                        }
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              inputProps={{ form: { autoComplete: 'off' } }}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpen(false)}>
            Cancelar
          </Button>

          <Button
            type='submit'
            variant='contained'
            disabled={isPending}
            startIcon={isPending && <CircularProgress size={16} sx={{ color: 'textSecondary' }} />}
          >
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
