import { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Table,
    Stack,
} from '@mui/material';
import { Add, Home as HomeIcon } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { atualizaUsuario, checkMaxPessoas, deletaUsuario, listaUsuariosFilter, resetAcessoUsuario, verifyCPFIsAlreadyRegistered } from './configs/functions';
import { useAppContext } from '../../../../contexts/AppContext';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { UserForm } from './components/UserForm';
import { CollaboratorRow } from './components/CollaboratorRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { WorkerIcon } from '../../../common/Icons';
import { Pagination } from '../../../common/Pagination';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions.js'
import { UserModalInfo } from './components/UserModalInfo.jsx';
import { listaEmpresas } from '../companies/configs/functions.js';
import { CollaboratorsTableFilter } from './components/CollaboratorsTableFilter';
import { FiltersButton } from '../../../common/FiltersButton.jsx';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow.jsx';
import ConfirmDialog from '../../../common/ConfirmDialog.jsx';
import { CustomToolbarExportTable } from '../../../common/CustomToolbarExportTable.jsx';
import { useModuleContext } from '../../../../contexts/ModuleProvider.jsx';
import { extractErrorMessage } from '../../../../utils/extract-error-message.js';

function ListOfCollaborators() {
    const queryClient = useQueryClient();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();
    const { exibirAlerta } = useCommonItems();
    const { verifyIntegrationMode } = useModuleContext();

    const [searchParams, setSearchParams] = useSearchParams();

    const [open, setFormOpen] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openDialogResetUser, setOpenDialogResetUser] = useState(false);
    const [turnAdmin, setTurnAdmin] = useState(false);

    const [moduleActionsAllowed, setModuleActionsAllowed] = useState(false);

    const searchParamPage = searchParams.get('page');
    const searchParamPerPage = searchParams.get('perPage');

    // estados para controle de paginacao
    const page = searchParamPage ? (parseInt(searchParamPage) - 1) : 0;
    const rowsPerPage = searchParamPerPage ? parseInt(searchParamPerPage) : 10;

    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [activeFilters, setActiveFilters] = useState(0);

    const [user, setUser] = useState(null);

    const columns = useMemo(() => [
        { field: 'matricula', headerName: 'Matrícula' },
        { field: 'nome', headerName: 'Nome' },
        { field: 'cpf', headerName: 'CPF' },
        { field: 'dtNascimento', headerName: 'Data de Nascimento' },
        { field: 'empresa.fantasyName', headerName: 'Empresa', valueGetter: (params) => params.row.empresa?.fantasyName },
        { field: 'status', headerName: 'Status', valueGetter: (params) => params.row.status === 1 ? 'Ativo' : 'Inativo' },
    ], []);

    const { data: empresasData, error: listaEmpresasError } = useQuery({
        queryKey: ['listaEmpresas'],
        queryFn: async () => {
            const response = await listaEmpresas();
            const empresasData = response.data.data;

            const selectedEmpresaId = getEmpresaIdSession() || empresasData[0]?.id;

            setEmpresaIdSession(selectedEmpresaId);

            setSearchParams((state) => {
                state.set('id_empresa', String(selectedEmpresaId));
                state.set('page', String(page + 1));
                state.set('perPage', String(rowsPerPage));

                return state;
            });
            return response;
        },
        refetchOnWindowFocus: false,
    });

    const queryFilters = {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        tipo_usuario: 'colaborador',
        nome: searchParams.get('nome'),
        cpf: searchParams.get('cpf'),
        matricula: searchParams.get('matricula'),
        id_empresa: searchParams.get('id_empresa'),
    };

    const queryKeyListCollaborators = ['listOfCollaborators', queryFilters];

    const { data: collaboratorsData, isLoading, isPending, error: listOfCollaboratorsError } = useQuery({
        queryKey: queryKeyListCollaborators,
        queryFn: () => listaUsuariosFilter(queryFilters),
        enabled: !!searchParamPage && !!searchParamPerPage,
    });

    const collaborators = collaboratorsData?.data?.data ?? [];
    const numTotalItems = collaboratorsData?.data?.numero_total ?? 0;
    const isLoadingList = isLoading || isPending;

    const { data: isMaxUsersAdmin } = useQuery({
        queryKey: ['checkMaxUsersAdmin', searchParams.get('id_empresa'), numTotalItems],
        queryFn: () => checkMaxPessoas(searchParams.get('id_empresa'), empresasData?.data?.data, 'administrador'),
        enabled: !!searchParams.get('id_empresa') && empresasData?.data?.data.length > 0,
        refetchOnWindowFocus: false,
    });

    function handleViewUser(user) {
        setUser(user);
        setOpenViewModal(true);
    }

    function handleEditUser(user) {
        setUser(user);
        setFormOpen(true);
    }

    const handleOpenDeleteDialog = (item) => {
        setUser(item);
        setOpenDeleteDialog(true);
    }

    const handleOpenDialogResetUser = (item) => {
        setUser(item);
        setOpenDialogResetUser(true);
    }

    const onUpdateStatus = async (updatedUser) => {
        const dataSendUpdate = { id: updatedUser.id, dataToUpdate: { status: !updatedUser.status } };

        if (updatedUser.cpf && !updatedUser.status) {
            const userExists = await verifyCPFIsAlreadyRegistered(updatedUser.cpf, updatedUser.empresa.id);
            if (userExists) {
                exibirAlerta(
                    'CPF já cadastrado!',
                    'Já existe um usuário ativo com esse CPF. Não é permitido ter dois usuários ativos com o mesmo CPF.',
                    'error'
                );
                return;
            }
        }

        updateStatusFn(dataSendUpdate)
    }

    const { mutateAsync: updateStatusFn, isPending: isPendingUpdateStatus } = useMutation({
        mutationFn: (data) => atualizaUsuario(data.id, data.dataToUpdate),
        onSuccess: (response, variables, context) => {
            const user = response.data.data;

            const message = user.status ? 'Colaborador ativado com sucesso.' : 'Colaborador inativado com sucesso.';
            exibirAlerta('Sucesso', message, 'success');

            updateCachedData(user);
        },
    });

    const { mutateAsync: handleDelete } = useMutation({
        mutationFn: deletaUsuario,
        onSuccess: (response, id) => {
            exibirAlerta('Sucesso', 'Colaborador inativado com sucesso.', 'success');
            queryClient.invalidateQueries({ queryKey: queryKeyListCollaborators });
        },
        onError: (error) => {
            const message = error.response?.data?.message ?? 'Erro ao executar operação';
            exibirAlerta('Erro', message, 'error');
        },
        onSettled: () => {
            setUser(null);
            setOpenDeleteDialog(false);
        },
    });

    const { mutateAsync: handleResetAcesso } = useMutation({
        mutationFn: resetAcessoUsuario,
        onSuccess: (response) => {
            exibirAlerta('Sucesso', 'Credenciais resetadas com sucesso.', 'success');
            const updatedUser = response.data.data;
            updateCachedData(updatedUser);
        },
        onError: (error) => {
            const message = error.response?.data?.message ?? 'Erro ao executar operação';
            exibirAlerta('Erro', message, 'error');
        },
        onSettled: () => {
            setUser(null);
            setOpenDialogResetUser(false);
        },
    });

    function updateCachedData(updatedUser) {
        // buscar em cache os dados da requisição e atualizar
        const cached = queryClient.getQueryData(queryKeyListCollaborators);
        const cachedData = cached.data;

        if (cached) {
            const updatedData = cachedData.data.map(item => {
                if (item.id === updatedUser.id) {
                    return updatedUser;
                }

                return item;
            });

            queryClient.setQueryData(queryKeyListCollaborators, {
                ...cached,
                data: {
                    ...cachedData,
                    data: updatedData
                }
            });
        }
    }

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_pessoas", "list_pessoas", "view_pessoas"];
        checkPermissionsAndRedirect(requiredPermissionsView);
    }, []);

    useEffect(() => {
        const queryFilters = {
            ...Object.fromEntries(searchParams),
        }

        const tableFilters = { ...queryFilters };
        delete tableFilters.page;
        delete tableFilters.perPage;

        const activeFilters = Object.keys(tableFilters).length;

        setActiveFilters(activeFilters);
        setIsOpenFilters(activeFilters > 0);

        const empresaHasActionsAllowed = verifyIntegrationMode("Pessoa", searchParams.get('id_empresa'));
        setModuleActionsAllowed(empresaHasActionsAllowed);
    }, [searchParams, verifyIntegrationMode]);

    useEffect(() => {
        if (!open) {
            setUser(null);
        }
    }, [open]);

    useEffect(() => {
        let errorMessage = "";

        if (listOfCollaboratorsError) {
            errorMessage = extractErrorMessage(listOfCollaboratorsError, 'Erro ao carregar a lista de colaboradores');
        } else if (listaEmpresasError) {
            errorMessage = extractErrorMessage(listaEmpresasError, 'Erro ao carregar a lista de empresas');
        }

        errorMessage && exibirAlerta('Ops! Ocorreu um erro', errorMessage, 'error');
    }, [listOfCollaboratorsError, listaEmpresasError, exibirAlerta]);

    return (
        <>
            <Helmet title="Colaboradores" defer={false} />

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="end"
                spacing={3}
            >
                <Grid item xs={12} md={9}>
                    <Breadcrumbs>
                        <LinkNavegacao to="/"><HomeIcon fontSize="small" /></LinkNavegacao>
                        <Typography variant='span'>Gerenciamento</Typography>
                        <LinkNavegacao to="/gerenciamento/pessoas">Pessoas</LinkNavegacao>
                        <Typography variant='span'>Colaboradores</Typography>
                    </Breadcrumbs>

                    <PageTitle
                        icon={<WorkerIcon fontSize="large" />}
                        title='Colaboradores'
                        description=' Administre os colaboradores de sua(s) empresa(s).'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} justifyContent={'space-between'}>
                        <FiltersButton
                            isOpenFilters={isOpenFilters}
                            setIsOpenFilters={setIsOpenFilters}
                            activeFilters={activeFilters}
                        />

                        {hasPermission(["admin", "admin_pessoas", "create_pessoas"]) &&
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                onClick={() => setFormOpen(true)}
                            >
                                Cadastrar Colaborador
                            </Button>
                        }
                    </Stack>
                </Grid>

                <CollaboratorsTableFilter
                    isOpenFilters={isOpenFilters}
                    isLoadingList={isLoadingList}
                    empresas={empresasData?.data?.data ?? []}
                />

                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <CustomToolbarExportTable
                            rows={collaborators}
                            columns={columns}
                            reportName="relatorio_colaboradores"
                            reportPdfTitle="RELATÓRIO DE COLABORADORES"
                            getDataWithoutPaginate={() => {
                                const { limit, offset, ...data } = queryFilters;
                                return listaUsuariosFilter(data);
                            }}
                            getSelectedEmpresa={() => empresasData.data.data.find(empresa => empresa.id === Number(searchParams.get('id_empresa')))}
                        />

                        <Table aria-label="Colaboradores">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Matrícula</TableCell>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Empresa</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoadingList ? (
                                    <SkeletonTableRow
                                        numCells={6}
                                        numRows={rowsPerPage < 20 ? rowsPerPage : 5}
                                    />
                                ) : (
                                    collaborators && collaborators.length === 0 ? (
                                        <EmptyTableRow infoType='colaborador' />
                                    ) : (
                                        collaborators.map((user) =>
                                            <CollaboratorRow
                                                key={user.id}
                                                user={user}
                                                actionsAllowed={moduleActionsAllowed}
                                                isMaxUsersAdmin={isMaxUsersAdmin}
                                                isPendingUpdate={isPendingUpdateStatus}
                                                handleUpdateStatus={() => onUpdateStatus(user)}
                                                handleOpenViewModal={() => handleViewUser(user)}
                                                handleOpenEditForm={() => handleEditUser(user)}
                                                handleOpenEditTurnAdminForm={() => {
                                                    setTurnAdmin(true)
                                                    handleEditUser(user)
                                                }}
                                                handleOpenDeleteDialog={() => handleOpenDeleteDialog(user)}
                                                handleOpenDialogResetUser={() => handleOpenDialogResetUser(user)}
                                            />
                                        )
                                    )
                                )}
                            </TableBody>
                        </Table>

                        <Pagination
                            numTotalItems={numTotalItems}
                            rowsPerPage={rowsPerPage}
                            page={page}
                        />
                    </TableContainer>
                </Grid>
            </Grid >

            <UserForm
                open={open}
                setOpen={setFormOpen}
                isMaxLimitTipoPessoa={isMaxUsersAdmin}
                pessoa={user}
                userType='colaborador'
                queryKey={queryKeyListCollaborators}
                changeUserToAdmin={turnAdmin}
                actionsAllowed={moduleActionsAllowed}
            />

            <UserModalInfo
                open={openViewModal}
                setOpen={(event) => {
                    setOpenViewModal(event)
                    if (!event) setUser(null);
                }}
                pessoa={user}
            />

            <ConfirmDialog
                description='Tem certeza que deseja resetar as credenciais de acesso desse colaborador? Isso removerá a senha e a Biometria facial cadastrada.'
                title='Resetar Credenciais'
                goAction={() => handleResetAcesso(user.id)}
                handleClose={() => {
                    setOpenDialogResetUser(false);
                    setUser(null);
                }}
                state={openDialogResetUser}
            />

            <ConfirmDialog
                description='Tem certeza que deseja inativar esse colaborador?'
                title='Inativar Colaborador'
                goAction={() => handleDelete(user.id)}
                handleClose={() => {
                    setOpenDeleteDialog(false);
                    setUser(null);
                }}
                state={openDeleteDialog}
            />
        </>
    );
}

export default ListOfCollaborators;