import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  StepButton,
  Box,
  DialogContentText,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import { fillInputErrors } from '../../../../../../configs/functions';
import { SecondStepEquipmentForm } from './SecondStep';
import { FirstStepEquipmentForm } from './FirstStep';
import { useCommonItems } from '../../../../../../contexts/CommonItensProvider';
import { carregaConfigsEquipamento, manipulaEquipamento } from '../../configs/functions';
import { ThirdStepEquipmentForm } from './ThirdStep';
import { formulaCalRotationTimes, getDefaultValueSelectField } from '../../../../../../utils';

export function MachineEquipmentForm({ open, setOpen, equipamento, empresas, queryKey }) {
  const queryClient = useQueryClient();
  const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const isEditAction = !!equipamento;
  const [activeStep, setActiveStep] = useState(0);
  const defaultRotationTimeValues = Array.from({ length: 256 }, (_, index) => formulaCalRotationTimes(index));

  // Função para inicializar o array de motores
  const createArrayMotores = qtdMotores => {
    return Array.from({ length: qtdMotores }, (_, index) => ({
      id: "",
      id_equipamento: equipamento ? equipamento.id : "",
      id_produto: "",
      id_produto_pai: "",
      mustSelectChild: false,
      numero_motor: index + 1,
      status: 'livre',
      tempo_rotacao: defaultRotationTimeValues[defaultRotationTimeValues.length / 2],
      multiplicador: 1,
      numero_rotacoes: 1,
      agrupado_por: null,
      qtd_produto: 0,
      capacidade: 1,
      is_dirty: false,
    }));
  };

  const [qtdMotores, setQtdMotores] = useState(36);
  // array de motores (configsEquipamento)
  const [motores, setMotores] = useState(createArrayMotores(36));
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { dirtyFields, errors },
    trigger,
    setError,
    clearErrors,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    let formDataToSend = {};
    exibirDialog('Salvando. Aguarde...');

    if (isEditAction) {
      const dirtyFieldKeys = Object.keys(dirtyFields);

      const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
        if (data.hasOwnProperty(key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      if (Object.keys(dirtyData).length === 0 && !motores.some(motor => motor.is_dirty)) {
        exibirAlerta('Atenção', 'Nenhum dado foi alterado.', 'warning');
        setOpen(false);
        handleCloseDialog();
        return;
      }

      formDataToSend = dirtyData;
      formDataToSend.id = equipamento.id;
    } else {
      delete data.id;

      if (!data.tag_identificacao) {
        delete data.tag_identificacao;
      }

      formDataToSend = data;
    }

    // percorrer motores e verificar se tempo_rotacao consta no array defaultRotationTimeValues

    // como está salvando com toFixed(2), não realizar a validação agora, pois esta travando edit
    // const motorComTempoRotacaoInvalido = motores.find(motor => !defaultRotationTimeValues.includes(motor.tempo_rotacao));

    // if (motorComTempoRotacaoInvalido) {
    //   exibirAlerta('Ops', 'O tempo de rotação do motor ' + motorComTempoRotacaoInvalido.numero_motor + ' é inválido.', 'error');
    //   handleCloseDialog();
    //   return;
    // }

    // percorrer todos os motores e verificar se alguma possui produto pai mas não foi selecionada a variação
    const motorComProdutoPaiSemVariacao = motores.find(motor => !motor.id_produto && motor.mustSelectChild);

    if (motorComProdutoPaiSemVariacao) {
      exibirAlerta('Ops', 'Selecione a variação do produto para o motor ' + motorComProdutoPaiSemVariacao.numero_motor, 'error');
      handleCloseDialog();
      return;
    } else {
      // remover prop para não enviar ao backend
      motores.forEach(motor => {
        delete motor.id_produto_pai;
        delete motor.mustSelectChild;
      });
    }

    formDataToSend.configsEquipamento = motores;

    try {
      const res = await manipulaEquipamento(formDataToSend, isEditAction);
      exibirAlerta('Sucesso', res.data.message, 'success');
      setOpen(false);

      // quando backend passar a retornar objeto criado/editado, fazer as operacoes com o cache, ao inves de fazer o refetch
      // assim como foi feito no DocumentTypeForm.jsx

      // enquanto nao tem o obj criado/editado, invalidar a query para atualizar a lista
      queryClient.invalidateQueries({ queryKey });
    } catch (error) {
      let errorMessage = "Erro ao cadastrar Equipamento.";
      if (error.response) {
        errorMessage = error.response.data.message
        fillInputErrors(error, setError);
      }
      exibirAlerta('Erro', errorMessage, 'error');
    } finally {
      handleCloseDialog();
    }
  };

  const validaCamposStep01 = async () => {
    const isValidNome = await trigger('nome');
    const isValidTagIdentificacao = await trigger('tag_identificacao');
    const isValidDescricao = await trigger('descricao');
    const isValidTipo = await trigger('tipo');
    const isValidTipoContrato = await trigger('tipo_contrato');
    const isValidEmpresa = await trigger('id_empresa');
    const isValidPontoEntrega = await trigger('id_ponto_entrega');

    const state =
      isValidNome &&
      isValidDescricao &&
      isValidTagIdentificacao &&
      isValidTipo &&
      isValidEmpresa &&
      isValidPontoEntrega &&
      isValidTipoContrato;

    return state;
  };

  const handleNext = async (event) => {
    event.preventDefault();
    switch (activeStep) {
      case 0:
        if (await validaCamposStep01()) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 1:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 2:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (pos) => {
    if (pos < activeStep) {
      setActiveStep(pos)
    }
  }

  const steps = ['Dados do Equipamento', 'Organização da Máquina', 'Configurações dos Motores'];

  const loadConfigsEquipamento = async () => {
    try {
      setIsLoading(true);
      const filter = { id_equipamento: equipamento.id }
      const response = await carregaConfigsEquipamento(filter);
      response.data.data.forEach((motor) => {
        motor.is_dirty = false;
      });
      setMotores(response.data.data);
    } catch (error) {
      exibirAlerta('Erro ao carregar as Configurações do Equipamento', '', 'error');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setActiveStep(0);

    // preenche o formulário ao abrir p/ edicao
    if (open) {
      const defaultValueIdEmpresa = getDefaultValueSelectField(equipamento, equipamento?.empresa?.id, empresas);

      const defaultValues = {
        id: equipamento ? equipamento.id : '',
        nome: equipamento ? equipamento.nome : '',
        tag_identificacao: equipamento ? equipamento.tag_identificacao : '',
        status: equipamento ? equipamento.status : 1,
        tipo: equipamento ? equipamento.tipo : 'maquina',
        tipo_contrato: equipamento ? equipamento.tipo_contrato : 'locacao',
        descricao: equipamento ? equipamento.descricao : '',
        id_empresa: defaultValueIdEmpresa,
        id_ponto_entrega: equipamento ? equipamento.pontoEntrega.id : '',
      };

      reset(defaultValues);
      setQtdMotores(equipamento ? equipamento.qtd_motores : 36);

      // get the configEquipamento from the equipamento
      if (equipamento) {
        loadConfigsEquipamento();
      }
    } else {
      reset();
      setMotores([]);
      setQtdMotores(36);
    }
  }, [open, reset]);

  useEffect(() => {
    if (open) {
      setMotores(createArrayMotores(qtdMotores))
    }
  }, [open, qtdMotores]);

  return (
    <Dialog open={open} fullWidth maxWidth={'lg'} disableEscapeKeyDown>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isEditAction
          ? <DialogTitle>Editando Equipamento <strong>{equipamento.nome}</strong></DialogTitle>
          : <DialogTitle>Cadastrar novo Equipamento</DialogTitle>
        }
        <DialogContent>
          <DialogContentText>
            {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar um novo Equipamento.'}
          </DialogContentText>

          <Stepper activeStep={activeStep} sx={{ px: { xs: 2, sm: 10 }, py: 2, overflowX: 'scroll' }} orientation={isMobile ? 'vertical' : 'horizontal'}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton color="inherit" onClick={() => handleStep(index)}>
                  <StepLabel>{label}</StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>

          <Box sx={{ p: 2, backgroundColor: '#fafafa' }} >
            <FormControl variant="standard" fullWidth>
              {activeStep === 0 &&
                <FirstStepEquipmentForm
                  values={getValues()}
                  equipamento={equipamento}
                  empresas={empresas}
                  register={register}
                  errors={errors}
                  clearErrors={clearErrors}
                  control={control}
                  setQtdMotores={setQtdMotores}
                  setValue={setValue}
                />
              }
              {activeStep === 1 &&
                <SecondStepEquipmentForm
                  motores={motores}
                  setMotores={setMotores}
                  qtdMotores={qtdMotores}
                />}
              {activeStep === 2 &&
                <ThirdStepEquipmentForm
                  values={getValues()}
                  equipamento={equipamento}
                  motores={motores}
                  setMotores={setMotores}
                  qtdMotores={qtdMotores}
                />}
            </FormControl>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="contained">
            Cancelar
          </Button>

          <Divider orientation="vertical" flexItem />

          {activeStep !== 0 && (
            <Button onClick={handleBack} variant="outlined" type="button">
              Voltar
            </Button>
          )}
          {activeStep !== steps.length - 1 ? (
            <Button onClick={handleNext} variant="contained" type="button" disabled={isLoading}>
              {!isLoading
                ? 'Próximo'
                : <><CircularProgress size={16} sx={{ marginRight: 1 }} />Carregando</>
              }
            </Button>
          ) : (
            <Button type="submit" variant="contained">
              Salvar
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}
