import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  Grid,
  Breadcrumbs,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  IconButton,
  Switch,
} from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { EditIcon, WritingDownIcon } from '../../../common/Icons';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow';
import { listaEmpresas } from '../companies/configs/functions';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { useAppContext } from '../../../../contexts/AppContext';
import { atualizaConfigEmpresa, listaConfigEmpresa } from './configs/functions';
import { ConfigEmpresaForm } from './components/ConfigEmpresaForm';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { extractErrorMessage } from '../../../../utils/extract-error-message';

const ConfigEmpresaRow = ({ data, handleOpenEditForm, handleUpdateStatus }) => {
  return (
    <TableRow>
      <TableCell>
        {data.empresa.nomeCompanhia}
      </TableCell>

      <TableCell>
        <Switch
          checked={!!data.exibirFotos}
          onChange={handleUpdateStatus}
          disabled={!hasPermission(["admin", "admin_configEmpresa", "update_configEmpresa"])}
        />
      </TableCell>

      <TableCell
        sx={{
          maxWidth: 450,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {data.termoResponsabilidade}
      </TableCell>
      <TableCell
        sx={{
          maxWidth: 450,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {data.termoAceite}
      </TableCell>
      <TableCell align='right' sx={{ minWidth: 110 }}>
        {hasPermission(["admin", "admin_configEmpresa", "update_configEmpresa"]) && (
          <IconButton aria-label="Editar" onClick={() => handleOpenEditForm(data)}>
            <EditIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export function ListOfConfigEmpresa() {
  const { exibirAlerta } = useCommonItems();
  const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

  const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedConfigEmpresa, setSelectedConfigEmpresa] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState(getEmpresaIdSession());

  const queryKey = ['listaConfigEmpresa', selectedEmpresaId, page, rowsPerPage];

  const { data: listaConfigEmpresaData, isPending: listaConfigEmpresaIsLoading } = useQuery({
    queryKey,
    queryFn: () =>
      listaConfigEmpresa({
        id_empresa: selectedEmpresaId,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      }),
    enabled: !!selectedEmpresaId,
  });

  const { data: queryEmpresasData } = useQuery({
    queryKey: ['listaEmpresas'],
    queryFn: async () => {
      const response = await listaEmpresas();
      const empresasData = response.data.data;

      const selectedEmpresaIdSession = getEmpresaIdSession();

      if (selectedEmpresaIdSession) {
        setSelectedEmpresaId(selectedEmpresaIdSession);
      }
      else if (empresasData.length > 0) {
        setSelectedEmpresaId(empresasData[0].id);
        setEmpresaIdSession(empresasData[0].id);
      }

      return response;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const requiredPermissionsView = ['admin', 'admin_configEmpresa', 'list_configEmpresa'];
    checkPermissionsAndRedirect(requiredPermissionsView);
  }, []);

  const tiposDocumento = listaConfigEmpresaData?.data?.data ?? [];
  const numTotalItems = listaConfigEmpresaData?.data?.numero_total ?? 0;

  const empresas = queryEmpresasData?.data?.data ?? [];

  const handleEdit = (item) => {
    setSelectedConfigEmpresa(item);
    setOpenForm(true);
  };

  const handleUpdateStatus = useMutation({
    mutationFn: (data) => atualizaConfigEmpresa(data.id, data.dataToUpdate),
    onSuccess: (response, variables, context) => {
      const message = response?.data?.message || "Configuração atualizada com sucesso";
      exibirAlerta('Sucesso', message, 'success');

      queryClient.invalidateQueries({ queryKey });
    },
    onError: (error, variables, context) => {
      const errorMessage = extractErrorMessage(error, 'Senha incorreta. Tente novamente');
      exibirAlerta('Ops, ocorreu um erro.', errorMessage, 'error');
    }
  });

  return (
    <>
      <Helmet title="Configuração de Termos para Documentos de EPI" defer={false} />

      <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs>
            <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
            <Typography variant='span'>Gerenciamento</Typography>
            <LinkNavegacao to='/gerenciamento/configuracoes'>Configurações</LinkNavegacao>
            <Typography variant="span">Configuração de Termos para Documentos de EPI</Typography>
          </Breadcrumbs>

          <PageTitle
            icon={<WritingDownIcon fontSize="large" />}
            title="Configuração de Termos para Documentos de EPI"
            description="Configure as informações dos seus documentos."
          />
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="Configuração de Termos para Documentos de EPI">
              <TableHead>
                <TableRow>
                  <TableCell>Empresa</TableCell>
                  <TableCell>Exibir Captura de Biometria</TableCell>
                  <TableCell>Termo de Responsabilidade</TableCell>
                  <TableCell>Termo de Aceite</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {listaConfigEmpresaIsLoading ? (
                  <SkeletonTableRow
                    numCells={5}
                    numActions={1}
                    numRows={rowsPerPage < 20 ? rowsPerPage : 5}
                  />
                ) : tiposDocumento.length > 0 ? (
                  tiposDocumento.map((item) => (
                    <ConfigEmpresaRow
                      key={item.id}
                      data={item}
                      handleOpenEditForm={() => handleEdit(item)}
                      handleUpdateStatus={() => {
                        const dataSendUpdate = { id: item.id, dataToUpdate: { exibir_fotos: !item.exibirFotos } };
                        handleUpdateStatus.mutateAsync(dataSendUpdate)
                      }}
                    />
                  ))
                ) : (
                  <EmptyTableRow />
                )}
              </TableBody>
            </Table>

            <CustomTablePagination
              numTotalItems={numTotalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>

      <ConfigEmpresaForm
        open={openForm}
        setOpen={(value) => {
          setOpenForm(value);
          if (!value) {
            setSelectedConfigEmpresa(null);
          }
        }}
        configEmpresa={selectedConfigEmpresa}
        empresas={empresas}
        queryKey={queryKey}
      />
    </>
  );
}