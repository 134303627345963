import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    FormHelperText,
    Grid,
    TextField,
    Typography,
    CircularProgress,
    Stack
} from '@mui/material';
import { listaTamanhosEVariacoes } from '../../components/screens/epis/products/configs/functions';
import { useCommonItems } from '../../contexts/CommonItensProvider';
import { extractErrorMessage } from '../../utils/extract-error-message';

// Função para cada item de tamanho/variacao
const ItemTamanho = ({ data, register, setValue, getValues, errors, clearErrors, actionsAllowed }) => {
    const [ativo, setAtivo] = useState(getValues(`variacoes.${data.id}.ativo`) || false);
    const [codExternoValue, setCodExternoValue] = useState(getValues(`variacoes.${data.id}.cod_externo`) || '');

    const alreadyExists = !!getValues(`variacoes.${data.id}.id_variacao`);
    const hasError = !!errors.variacoes?.[data.id]?.cod_externo;

    const handleCheckboxChange = (e) => {
        if (!actionsAllowed) return;

        const isChecked = e.target.checked;
        setAtivo(isChecked);

        setValue(`variacoes.${data.id}.ativo`, isChecked);
        setValue(`variacoes.${data.id}.is_dirty`, true);

        if (isChecked && !alreadyExists) {
            setValue(`variacoes.${data.id}.is_new`, true);
            setValue(`variacoes.${data.id}.rotulo`, data.tipo);
            setValue(`variacoes.${data.id}.id_variacao`, data.id);
        }

        if (!isChecked) {
            clearErrors(`variacoes.${data.id}.cod_externo`);

            const isNew = getValues(`variacoes.${data.id}.is_new`) || false;
            if (isNew) {
                setValue(`variacoes.${data.id}.cod_externo`, '');
                setCodExternoValue('');
                setValue(`variacoes.${data.id}.rotulo`, '');
                setValue(`variacoes.${data.id}.is_new`, false);
                setValue(`variacoes.${data.id}.id_variacao`, '');
            }
        }
    };

    return (
        <Grid item xs={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        {...register(`variacoes.${data.id}.ativo`)}
                        checked={ativo}
                        onChange={handleCheckboxChange}
                        sx={{ cursor: actionsAllowed ? 'pointer' : 'not-allowed' }}
                        inputProps={{ readOnly: !actionsAllowed }}
                    />
                }
                label={data.tipo.trim()}
            />
            <TextField
                label="Código"
                variant="outlined"
                value={codExternoValue}
                size="small"
                disabled={!ativo}
                InputProps={{
                    readOnly: !actionsAllowed,
                }}
                onChange={(e) => {
                    const value = e.target.value.toUpperCase();
                    setValue(`variacoes.${data.id}.cod_externo`, value);
                    setValue(`variacoes.${data.id}.is_dirty`, true);
                    setCodExternoValue(value);
                }}
                error={hasError}
                helperText={errors.variacoes?.[data.id]?.cod_externo?.message}
            />
        </Grid>
    );
};

function VariationsCheckbox({ register, setValue, getValues, errors, clearErrors, actionsAllowed, codigoExistente, setError }) {
    const { exibirAlerta } = useCommonItems();

    const queryKeyListProducts = ['listOfVariations'];
    const { data: productsData, isLoading, isPending, error: listOfVariationsError } = useQuery({
        queryKey: queryKeyListProducts,
        queryFn: () => listaTamanhosEVariacoes(),
    });

    const variations = productsData?.data?.data ?? [];

    const isLoadingList = isLoading || isPending;

    useEffect(() => {
        let errorMessage = "";

        if (listOfVariationsError) {
            errorMessage = extractErrorMessage(listOfVariationsError, 'Erro ao carregar a lista de produtos');
        }

        errorMessage && exibirAlerta('Ops! Ocorreu um erro', errorMessage, 'error');
    }, [listOfVariationsError, exibirAlerta]);

    return (
        <Box>
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{ mb: 4 }}>Tamanhos/Variações do produto</FormLabel>
                <FormGroup>
                    <FormControl>
                        <FormGroup>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                {isLoadingList ?
                                    <Grid item xs={12}>
                                        <Stack spacing={2} width={'100%'} direction="row" alignItems="center" justifyContent='center' my={1} gap={1} sx={{ mt: 2 }}>
                                            <CircularProgress size={20} />
                                            <Typography variant='body2'>
                                                Carregando unidades de medida...
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    : variations && variations.map((data) => (
                                        <ItemTamanho
                                            key={data.id}
                                            data={data}
                                            register={register}
                                            setValue={setValue}
                                            getValues={getValues}
                                            errors={errors}
                                            clearErrors={clearErrors}
                                            actionsAllowed={actionsAllowed}
                                        />
                                    ))
                                }
                            </Grid>
                        </FormGroup>
                    </FormControl>
                </FormGroup>
                {variations && (
                    <FormHelperText>Selecione as variações desejadas e digite os Códigos correspondentes</FormHelperText>
                )}
            </FormControl>
        </Box>
    );
}

export default VariationsCheckbox;
