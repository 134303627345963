import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { FilterAlt, Home as HomeIcon } from '@mui/icons-material';
import { WorkerIcon } from '../../../common/Icons';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled';
import { useAppContext } from '../../../../contexts/AppContext';
import { checkPermissionsAndRedirect, getFormErrorMessage, verifyFieldsAreFilled } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { exportSaidasProdutosToPDF } from './configs/utils';
import { getSaidasProdutosData } from './configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { CustomToolbarReports } from '../../../common/CustomToolbarReports';
import { listaProdutos } from '../products/configs/functions';
import DateRangePicker from '../../../common/DateRangePicker';
import { formatDateTime, parseDateTime } from '../../../../utils/format-date';
import { AutocompleteProducts } from '../../../common/AutocompleteProducts';

export function SaidaProdutos() {
  const { exibirAlerta } = useCommonItems();
  const { dadosUsuario, getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

  // estados para controle de paginacao
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(20);
  const [numTotalItems, setNumTotalItems] = useState(0);

  const [dateRange, setDateRange] = useState([null, null]);
  const [dataSaidaProdutos, setDataSaidaProdutos] = useState([]);
  const [isDaily, setIsDaily] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);

  const {
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const columns = useMemo(() => {
    const baseColumns = [
      { field: 'produtoCa', headerName: 'CA', minWidth: 100, flex: 1 },
      { field: 'produtoNome', headerName: 'Produto', minWidth: 330, flex: 1.5 },
      { field: 'produtoCodExterno', headerName: 'Cód. Produto', minWidth: 120, flex: 1 },
      { field: 'quantidade', headerName: 'Quantidade', minWidth: 100, flex: 1 },
    ];

    if (isDaily) {
      baseColumns.push({
        field: 'dataSaida',
        headerName: 'Data Saída',
        minWidth: 200,
        flex: 1,
        valueGetter: (params) => parseDateTime(params.row?.dataSaida, "DD-MM-YYYY"),
        renderCell: (params) => formatDateTime(params.row?.dataSaida, "DD/MM/YYYY", "DD-MM-YYYY"),
      });
    } else {
      baseColumns.push({ field: 'saidas', headerName: 'Saídas', minWidth: 100, flex: 1 });
    }

    setNumTotalItems(0);
    setDataSaidaProdutos([]);

    return baseColumns;
  }, [isDaily]);

  const { data: empresasData, error: listaEmpresasError, isLoading: isLoadingEmpresasData, isPending } = useQuery({
    queryKey: ['listaEmpresas'],
    queryFn: async () => {
      const response = await listaEmpresas();
      const empresasData = response?.data.data;

      let defaultEmpresaId = null;
      const selectedEmpresaIdSession = getEmpresaIdSession();
      if (selectedEmpresaIdSession) {
        defaultEmpresaId = selectedEmpresaIdSession;
      } else {
        defaultEmpresaId = empresasData[0].id;
        setEmpresaIdSession(empresasData[0].id);
      }

      setSelectedEmpresaId(defaultEmpresaId);

      const defaultValues = {
        id_empresa: defaultEmpresaId,
        id_produto: '',
        data_inicio: dayjs().subtract(1, 'month'),
        data_fim: dayjs(),
      };
      reset(defaultValues);

      return response;
    },
    refetchOnWindowFocus: false,
  });

  const empresas = empresasData?.data.data || [];
  const isLoadingEmpresas = isLoadingEmpresasData || isPending;

  async function loadReportData(id_empresa, id_produto, data_inicio, data_fim) {
    try {
      setIsLoading(true);

      data_inicio = dayjs(data_inicio).format('YYYY-MM-DD');
      data_fim = dayjs(data_fim).format('YYYY-MM-DD');

      const filter = {
        id_empresa,
        diario: isDaily,
        data_inicio,
        data_fim,
        // limit: rowsPerPage,
        // offset: page * rowsPerPage,
      };

      if (id_produto === 'all') {
        filter.produto = 1
      } else {
        filter.id_produto = id_produto
      }

      const res = await getSaidasProdutosData(filter);

      setDataSaidaProdutos(res.data.saidas);
      setNumTotalItems(parseInt(res.data.numero_total));
    } catch (error) {
      console.log(error);
      exibirAlerta('Ops', 'Ocorreu um erro ao carregar dados do relatório.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsSearched(true);
      loadReportData(data.id_empresa, data.id_produto, data.data_inicio, data.data_fim);
    } catch (error) {
      exibirAlerta('Erro', 'Erro ao carregar dados do relatório', 'error');
      setIsSearched(false);
    }
  };

  const carregaProdutos = async (empresaId) => {
    if (!empresaId) return;
    try {
      setIsLoadingProducts(true);
      const res = await listaProdutos({ id_empresa: empresaId });
      setProdutos(res?.data.data);
    } catch (error) {
      exibirAlerta('Erro ao carregar os Produtos', '', 'error');
    } finally {
      setIsLoadingProducts(false);
    }
  }

  async function getDataEmpresa(empresaId) {
    setValue('id_empresa', selectedEmpresaId);
    await carregaProdutos(empresaId);
  }

  useEffect(() => {
    const requiredPermissionsView = ["admin", "admin_relatorio"];
    checkPermissionsAndRedirect(requiredPermissionsView);

    return () => {
      reset();
    }
  }, []);

  useEffect(() => {
    getDataEmpresa(selectedEmpresaId);
  }, [selectedEmpresaId]);

  useEffect(() => {
    const id_empresa = selectedEmpresaId
    const id_produto = getValues('id_produto');
    const data_inicio = getValues('data_inicio')
    const data_fim = getValues('data_fim')

    if (!id_empresa || !id_produto || !data_inicio || !data_fim) {
      return;
    }

    loadReportData(id_empresa, id_produto, data_inicio, data_fim);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listaEmpresasError) {
      exibirAlerta('Ops', 'Ocorreu um erro ao carregar dados das empresas', 'error');
    }
  }, [exibirAlerta, listaEmpresasError])

  function exportDataToPDF(visibleColumns, reportName) {
    try {
      if (!getValues('id_produto')) {
        return exibirAlerta('Ops', 'Selecione um colaborador para exportar', 'warning');
      }

      // if date range is not selected
      if (!getValues('data_inicio') || !getValues('data_fim')) {
        return exibirAlerta('Ops', 'Selecione um período para exportar', 'warning');
      }

      exportSaidasProdutosToPDF({
        tableData: dataSaidaProdutos,
        data_inicio: getValues('data_inicio'),
        data_fim: getValues('data_fim'),
        visibleColumns,
        reportName,
        isDaily,
        selectEmpresa: empresas.find(empresa => empresa.id == selectedEmpresaId),
        dadosUsuario
      })
    } catch (error) {
      exibirAlerta('Ops', 'Erro ao exportar dados para PDF', 'error');
      console.log(error);
    }
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Breadcrumbs>
          <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
          <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
          <LinkNavegacao to='/epis/relatorios'>Relatórios</LinkNavegacao>
          <Typography variant='span'>Relatório de saídas de Produtos</Typography>
        </Breadcrumbs>
        <PageTitle icon={<WorkerIcon fontSize='large' />} title="Relatório de Saídas de Produtos" />
      </Grid>

      <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container item xs={12} md={12} spacing={3}>
          <Grid item xs={12} md={6}>
            <SelectEmpresaFilter
              empresas={empresas}
              id_empresas={empresas}
              defaultValue={selectedEmpresaId}
              onChangeValue={(value) => {
                setIsSearched(false);
                setValue('id_empresa', value, { shouldDirty: true });
                setSelectedEmpresaId(value);
                setEmpresaIdSession(value);
                setValue('id_produto', null, { shouldDirty: true })
              }}
              textHelper={false}
              isLoading={isLoadingEmpresas}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
              <AutocompleteProducts
                name='id_produto'
                control={control}
                rules={{ required: true }}
                produtos={produtos}
                onChange={(e, value) => {
                  setIsSearched(false);
                  setValue('id_produto', value ? value.id : null, { shouldDirty: true });
                  if (value) {
                    clearErrors('id_produto');
                  }
                }}
                errors={errors}
                isLoading={isLoadingProducts}
                disabled={isLoadingProducts || !selectedEmpresaId}
                helperText={getFormErrorMessage(errors, 'id_produto')}
              />
            </TooltipSelectDisabled>
          </Grid>
        </Grid>

        <Grid container item xs={12} md={12} spacing={3}>
          <Grid item xs={12} md={4} lg={3}>
            <DateRangePicker
              value={dateRange}
              bgColor='#fff'
              onChange={(value) => {
                setDateRange(value);
                const [start, end] = value;
                setValue('data_inicio', start ? dayjs(start).format('YYYY-MM-DD') : '');
                setValue('data_fim', end ? dayjs(end).format('YYYY-MM-DD') : '');
                setIsSearched(false);
              }}
              onReset={() => {
                setDateRange([null, null]);
                setValue('data_inicio', '');
                setValue('data_fim', '');
                setIsSearched(false);
              }}
              hasError={errors.data_inicio || errors.data_fim}
            />
          </Grid>

          <Grid item xs={8} md={2.5}>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDaily}
                    onChange={() => setIsDaily(prev => !prev)}
                    color="primary"
                  />
                }
                label="Separar por dia"
              />
            </Stack>
          </Grid>

          <Grid item xs={4} md={1}>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              disabled={isLoading || Object.keys(errors).length > 0 || !verifyFieldsAreFilled([getValues('id_produto'), getValues('data_inicio'), getValues('data_fim')])}
              startIcon={isLoading ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <FilterAlt />}
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          height: dataSaidaProdutos.length > 0 ? "auto" : 400,
          minHeight: 400,
          width: '100%',
          '& .MuiDataGrid-columnHeaders, .MuiDataGrid-toolbarContainer': {
            backgroundColor: '#d8d8d8',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderRadius: 0
          },
        }}>
          <DataGrid
            getRowId={(row) => `${row.idRetirada}`}
            rows={dataSaidaProdutos}
            columns={columns}
            loading={isLoading}
            pageSizeOptions={[10, 20, 50, 100]}
            rowCount={numTotalItems}
            paginationMode="client"
            // paginationModel={{ pageSize: rowsPerPage, page: page }}
            // onPaginationModelChange={({ page, pageSize }) => {
            //   setPage(page);
            //   setRowsPerPage(pageSize);
            // }}
            sx={{
              backgroundColor: '#fff',
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold !important',
                overflow: 'visible !important',
              }
            }}
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            slots={{
              toolbar: (props) =>
                <CustomToolbarReports
                  reportName="relatorio_saidas_produtos_funcionario"
                  pdfExport={exportDataToPDF}
                  props={props}
                />,
              loadingOverlay: LinearProgress,
              noRowsOverlay: () => (
                <Box style={{ display: "flex", width: '100%', textAlign: 'center', height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                  {isSearched ?
                    <Typography variant='h6' color='textSecondary'>Nenhum registro encontrado para o filtro selecionado</Typography>
                    :
                    <Typography variant='h6' color='textSecondary'>Selecione um produto e o período que deseja filtrar</Typography>
                  }
                </Box>
              )
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}