import axios from "axios";
import {
  urlEmpresas,
  urlModuloEmpresa,
  urlExportacao,
  urlTiposExportacao,
  urlConfigEmpresa,
} from "../../../../../constants/endpoints";

export const generateIntegrationTokenEmpresa = (id) => axios.post(`${urlEmpresas}/gerarKey/${id}`)

// Modulo Empresa
export const carregaModuloEmpresas = (filter) => axios.get(urlModuloEmpresa, { params: filter })

export const cadastraModuloEmpresa = (data) => axios.post(`${urlModuloEmpresa}`, data) // designar módulos para as empresas

export const atualizaModuloEmpresa = (data) => axios.patch(`${urlModuloEmpresa}/${data.id_modulo_empresa}`, data);

export const manipulaModuloEmpresa = (data, isEditAction, isDeleteAction = false) =>
  isDeleteAction ? deletaModuloEmpresa(data.id_modulo_empresa)
    : isEditAction ? atualizaModuloEmpresa(data)
      : cadastraModuloEmpresa(data)

export const deletaModuloEmpresa = (id) => axios.delete(`${urlModuloEmpresa}/${id}`)

// exportação
export const listaExportacoes = (filter = null) => axios.get(urlExportacao, { params: filter });
export const cadastraExportacao = (data) => axios.post(`${urlExportacao}`, data);
export const deletaExportacao = (id) => axios.delete(`${urlExportacao}/${id}`);

// tipos exportação
export const listaTiposExportacao = (filter = null) => axios.get(urlTiposExportacao, { params: filter });
export const cadastraTipoExportacao = (data) => axios.post(`${urlTiposExportacao}`, data);
export const atualizaTipoExportacao = (id, data) => axios.patch(`${urlTiposExportacao}/${id}`, data);
export const manipulaTipoExportacao = (data, isEditAction) => isEditAction ? atualizaTipoExportacao(data.id, data) : cadastraTipoExportacao(data);
export const deletaTipoExportacao = (id) => axios.delete(`${urlTiposExportacao}/${id}`);

// get campos tabela
export const getCamposTabela = (filter = null) => axios.get(`${urlExportacao}/getCamposTabela`, { params: filter });

// configEmpresa
export const listaConfigEmpresa = (filter = null) => axios.get(urlConfigEmpresa, { params: filter });
export const cadastraConfigEmpresa = (data) => axios.post(`${urlConfigEmpresa}`, data);
export const atualizaConfigEmpresa = (id, data) => axios.patch(`${urlConfigEmpresa}/${id}`, data);
export const manipulaConfigEmpresa = (data, isEditAction) => isEditAction ? atualizaConfigEmpresa(data.id, data) : cadastraConfigEmpresa(data);
export const deletaConfigEmpresa = (id) => axios.delete(`${urlConfigEmpresa}/${id}`);