import { DialogActions, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

export function CommonDialogActionsForm({ errors, isLoading, setOpen, disableSubmit = false }) {
  return (
    <>
      {Object.keys(errors).length > 0 && (
        <Stack justifyContent="end" direction='row'>
          <Typography variant="caption" color="error" sx={{ px: 2, mt: 1 }}>
            Ao menos um campo não foi preenchido corretamente
          </Typography>
        </Stack>
      )}

      <DialogActions>
        <Button
          variant='contained'
          onClick={() => setOpen(false)}
        >
          {disableSubmit ? 'Fechar' : 'Cancelar'}
        </Button>

        {!disableSubmit && (
          <Button
            type='submit'
            variant='contained'
            disabled={Object.keys(errors).length > 0 || isLoading}
            startIcon={isLoading && <CircularProgress size={16} sx={{ color: grey[400] }} />}
          >
            {isLoading ? 'Salvando...' : 'Salvar'}
          </Button>
        )}
      </DialogActions>
    </>
  )
}
