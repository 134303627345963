import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Divider, Skeleton, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { generateColors } from '../../../../../configs/functions';

ChartJS.register(ArcElement, Tooltip, Legend);

// Função para adicionar "R$" à frente dos valores no tooltip
const tooltipCallback = (context) => {
    const label = context.dataset.label || '';
    const value = context.parsed || 0;
    return `${value}% - ${label}`;
};

const options = {
    plugins: {
        tooltip: {
            callbacks: {
                label: tooltipCallback,
            },
        },
        legend: {
            position: 'bottom'
        }
    },
};

const limit = 5;

function ConsumptionByCostCenter({ epiPieChartData, isLoading }) {
    const isEmpty = epiPieChartData == null || epiPieChartData.length === 0;

    const sortedData = [...epiPieChartData].sort((a, b) => b.percentual - a.percentual);

    const formattedData = sortedData.reduce((acc, item, index) => {
        if (index < limit) {
            acc.push(item);
        } else {
            if (!acc[limit]) {
                acc[limit] = {
                    nomeCentroCusto: 'Outros',
                    percentual: 0,
                };
            }
            acc[limit].percentual += item.percentual;
        }
        return acc;
    }, []);

    const namesCentroCusto = formattedData.map((item) => item.nomeCentroCusto);
    const percentualCentroCusto = formattedData.map((item) => item.percentual);

    const colors = generateColors();

    const data = {
        labels: namesCentroCusto,
        datasets: [
            {
                label: 'Em Consumo',
                data: percentualCentroCusto.map((percentual) => Number(percentual.toFixed(2))),
                borderWidth: 3,
                borderColor: colors,
                backgroundColor: colors.map((color) => `${color}99`),
            },
        ],
    };

    return (
        <>
            <Typography variant='h2' component={'h2'}>
                Consumo por Centro de Custo
            </Typography>

            <Divider sx={{ mb: 1 }} />

            {!isLoading
                ? (!isEmpty
                    ? <div style={{ maxWidth: '300px', margin: 'auto' }}>
                        <Pie data={data} options={options} />
                    </div>
                    : <Stack spacing={1} alignItems={'center'}>
                        <Skeleton variant="circular" width={140} height={140} animation={false} />
                        <Typography variant='body1' component={'p'} color={grey[700]}>Nenhum dado disponível</Typography>
                    </Stack>
                )
                : <Stack spacing={1} alignItems={'center'}>
                    <Skeleton variant="circular" width={140} height={140} />
                    <Skeleton variant="rectangular" width={'80%'} height={16} />
                    <Skeleton variant="rectangular" width={'80%'} height={16} />
                </Stack>
            }
        </>
    );
}

export default ConsumptionByCostCenter;

