import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  CircularProgress,
} from '@mui/material';

export function ConfirmEditMatriculaDialog({ open, selectedCollaborator, newMatriculaValue, goAction, handleClose, isLoading = false }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Confirmação de Edição de Matrícula
      </DialogTitle>

      <DialogContent dividers>
        <DialogContentText pb={2}>
          <Typography gutterBottom>
            <strong>Colaborador:</strong> {selectedCollaborator?.nome} <br />
            Matrícula atual: {selectedCollaborator?.matricula} | <strong>Nova matrícula: {newMatriculaValue}</strong>
          </Typography>

          <Typography gutterBottom>
            Ao alterar a matrícula, <strong>todos os registros de retiradas</strong> serão atualizados para o novo número.
          </Typography>

          <Typography fontWeight="bold" color="error" gutterBottom>
            Atenção: esta ação é irreversível.
          </Typography>
        </DialogContentText>

        <DialogContentText>
          <Typography>
            Deseja realmente continuar com a alteração?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>
          Cancelar
        </Button>

        <Button
          variant='contained'
          onClick={goAction}
          disabled={isLoading}
          startIcon={isLoading && <CircularProgress size={16} sx={{ color: 'textSecondary' }} />}
        >
          Estou ciente. Continuar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
