import dayjs from 'dayjs';

/**
 * Função para formatar datas.
 * @param {string} date - Data em formato string ('DD-MM-YYYY HH:mm:ss').
 * @param {string} outputFormat - Formato de saída (default: 'DD/MM/YYYY HH:mm').
 * @returns {string} Data formatada.
 */
export const formatDateTime = (date, outputFormat = 'DD/MM/YYYY HH:mm', inputFormat = null) => {
  if (!date) return '';
  return dayjs(date, inputFormat ?? 'DD-MM-YYYY HH:mm:ss').format(outputFormat);
};

/**
 * Função para analisar datas.
 * @param {string} date - Data em formato string ('DD-MM-YYYY HH:mm:ss').
 * @returns {Date|null} Objeto Date ou null se a data for inválida.
 */
export const parseDateTime = (date, format = 'DD-MM-YYYY HH:mm:ss') => {
  const parsedDate = dayjs(date, format);
  return parsedDate.isValid() ? parsedDate.toDate() : null;
};
