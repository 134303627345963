import { useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Box,
  Grid,
  FormControl,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
} from '@mui/material';
import { manipulaConfigEmpresa } from '../configs/functions';
import { useCommonItems } from '../../../../../contexts/CommonItensProvider';
import { fillInputErrors, getFormErrorMessage } from '../../../../../configs/functions';
import { CommonDialogActionsForm } from '../../../../common/CommonDialogActionsForm';
import { HelpIcon } from '../../../../common/Icons';

export function ConfigEmpresaForm({ open, setOpen, configEmpresa, empresas, queryKey }) {
  const queryClient = useQueryClient();

  const { exibirAlerta } = useCommonItems();

  const isEditAction = !!configEmpresa;

  const {
    control,
    register,
    handleSubmit,
    setError,
    reset,
    formState: { dirtyFields, errors, isSubmitting },
  } = useForm();

  const { mutateAsync: manipulaTipoDocumentoFn } = useMutation({
    mutationFn: (data) => manipulaConfigEmpresa(data, isEditAction),
  })

  async function handleFormSubmit(data) {
    try {
      let formDataToSend = {};

      if (isEditAction) {
        const dirtyFieldKeys = Object.keys(dirtyFields);

        const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
          if (data.hasOwnProperty(key)) {
            acc[key] = data[key];
          }
          return acc;
        }, {});

        if (Object.keys(dirtyData).length === 0) {
          exibirAlerta('Atenção', 'Nenhum dado foi alterado.', 'warning');
          return;
        }

        formDataToSend = dirtyData;
        formDataToSend.id = configEmpresa.id;
      } else {
        delete data.id;
        formDataToSend = data;
      }

      formDataToSend.exibir_fotos = data.exibir_fotos ? 1 : 0;

      const response = await manipulaTipoDocumentoFn(formDataToSend);
      if (!response) {
        throw new Error('Erro ao cadastrar Tipo de Documento.');
      }

      // invalidate e refetch para atualizar os dados
      queryClient.invalidateQueries({ queryKey });

      exibirAlerta('Sucesso', response.data.message, 'success');
      setOpen(false);
    } catch (error) {
      console.error(error);
      let errorMessage = "Erro ao cadastrar Tipo de Documento.";
      if (error.response) {
        errorMessage = error.response.data.message
        fillInputErrors(error, setError);
      }
      exibirAlerta('Erro', errorMessage, 'error');
    }
  }

  useEffect(() => {
    // preenche o formulário ao abrir p/ edicao
    if (open) {
      const defaultValues = {
        termo_aceite: configEmpresa?.termoAceite ?? '',
        termo_responsabilidade: configEmpresa?.termoResponsabilidade ?? '',
        exibir_fotos: configEmpresa?.exibirFotos ?? false,
      };

      reset(defaultValues);
    } else {
      reset();
    }
  }, [configEmpresa, empresas, open, reset]);

  return (
    <Dialog open={open} fullWidth maxWidth={"md"}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogTitle>Editar Configuração de Termos para Documentos de EPI</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Preencha corretamente os dados.
          </DialogContentText>

          <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
            <FormControl variant="standard" fullWidth>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12}>
                  <Controller
                    name='exibir_fotos'
                    control={control}
                    defaultValue={configEmpresa?.exibirFotos}
                    render={({ field: { onChange, value, ...field } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={value}
                            onChange={(event) => {
                              onChange(event.target.checked);
                            }}
                          />
                        }
                        label='Exibir Capturas de Biometria'
                      />
                    )}
                  />

                  <Tooltip title="Selecione se deseja exibir as capturas de biometria das retiradas na Ficha de EPI" placement="top">
                    <IconButton><HelpIcon /></IconButton>
                  </Tooltip>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    {...register("termo_responsabilidade", { required: true })}
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Termo de Responsabilidade'
                    placeholder='O Termo de Responsabilidade é obrigatório'
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={8}
                    minRows={6}
                    maxRows={Infinity}
                    error={!!errors.termo_responsabilidade}
                    helperText={getFormErrorMessage(errors, 'termo_responsabilidade')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Termo de Aceite'
                    placeholder='O Termo de Aceite é obrigatório'
                    fullWidth
                    multiline
                    rows={4}
                    minRows={3}
                    {...register("termo_aceite", { required: true })}
                    error={!!errors.termo_aceite}
                    helperText={getFormErrorMessage(errors, 'termo_aceite')}
                    variant="outlined"
                    autoComplete='off'
                    inputProps={{ form: { autoComplete: 'off' } }}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </DialogContent>

        <CommonDialogActionsForm errors={errors} isLoading={isSubmitting} setOpen={setOpen} />
      </form>
    </Dialog>
  );
}