import { useState } from 'react';
import { Box, Divider, MenuItem, Skeleton, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import ChartJS, { CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { generateColors } from '../../../../../configs/functions';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend
);

const options = {
    plugins: {
        legend: {
            position: "bottom"
        }
    },
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
            ticks: {
                stepSize: 1,
                // callback: (value) => Number.isInteger(value) ? value : null
            }
        },
    },
    animation: {
        duration: 1200,
        easing: 'easeOutQuart',
    },
};

const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

function RequestsByArea({ requestArea, isLoading, refetchRequestsByArea }) {
    requestArea = requestArea?.data || [];

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const isEmpty = requestArea == null || requestArea.length === 0;

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const colors = generateColors();

    // get data from current year
    const dataActualYear = requestArea.find((data) => data.year === selectedYear);
    const arrayData = dataActualYear?.data || [];

    const data = {
        labels,
        datasets: arrayData.map((data, index) => ({
            label: data.area.nome,
            data: labels.map((month) => {
                const monthData = data.meses.find(mes => mes.mes === labels.indexOf(month) + 1);
                return monthData ? monthData.totalItens : 0;
            }),
            borderWidth: 3,
            borderColor: `${colors[index]}`,
            backgroundColor: `${colors[index]}99`,
        })),
        // mock data
        // datasets: [
        //     {
        //         label: 'Departamento 1',
        //         data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
        //         borderWidth: 3,
        //         borderColor: `${colors[0]}`,
        //         backgroundColor: `${colors[0]}99`,
        //     },
        //     {
        //         label: 'Departamento 2',
        //         data: [2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5],
        //         borderWidth: 3,
        //         borderColor: `${colors[1]}`,
        //         backgroundColor: `${colors[1]}99`,
        //     },
        //     {
        //         label: 'Departamento 3',
        //         data: [5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3],
        //         borderWidth: 3,
        //         borderColor: `${colors[2]}`,
        //         backgroundColor: `${colors[2]}99`,
        //     },
        // ]
    };

    return (
        <>
            <Stack direction='row' spacing={2} alignItems='center' justifyContent='center' pb={1}>
                <Typography variant={'h2'} component={'h2'} sx={{ flex: 1, display: 'inline' }}>
                    Requisições fora de regra por Área
                </Typography>

                <TextField
                    select
                    value={selectedYear}
                    variant='outlined'
                    size='small'
                    onChange={(e) => {
                        const year = Number(e.target.value);
                        setSelectedYear(year);
                        refetchRequestsByArea(year);
                    }}
                    disabled={isLoading}
                >
                    {[...Array(3)].map((_, index) => {
                        const year = new Date().getFullYear() - index;
                        return (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Stack>

            <Divider />

            {!isLoading
                ?
                <div style={{ position: 'relative' }}>
                    {isEmpty && (
                        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1, background: grey[300], opacity: 0.4 }}>
                            <Typography sx={{ color: '#000', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 3 }}>
                                Nenhum dado disponível
                            </Typography>
                        </Box>
                    )}

                    <Bar
                        options={options}
                        data={data}
                        height={isSmallScreen && 240}
                    />
                </div>
                : <Skeleton variant="rectangular" width={'100%'} height={200} sx={{ mt: 1, mb: 20 }} />
            }
        </>
    );
}

export default RequestsByArea;