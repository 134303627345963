import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
    Breadcrumbs,
    Grid,
    Typography,
    Tooltip,
    IconButton,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Paper,
    Switch
} from '@mui/material';
import { Home as HomeIcon, Visibility } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { checkPermissionsAndRedirect, formatarCNPJ, hasPermission } from '../../../../configs/functions';
import { AddKeyIcon, DisconnectedIcon, EditIcon, ResetKeyIcon } from '../../../common/Icons';
import { generateIntegrationTokenEmpresa, listaEmpresas } from '../companies/configs/functions';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { useMutation } from '@tanstack/react-query';
import { GenerateIntegrationTokenDialog } from '../companies/components/GenerateIntegrationTokenDialog';
import { ConfirmGenerateIntegrationTokenDialog } from '../companies/components/ConfirmGenerateIntegrationTokenDialog';
import { PasswordConfirmationDialog } from '../../../common/PasswordConfirmationDialog';
import { IntegrationForm } from './components/IntegrationForm';
import { listaModulos } from '../../admin/configs/functions';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow';

const CompanyRow = ({ empresa, isAdminSempher, handleOpenEditForm, handleOpenConfirmGenerateTokenDialog }) => {
    const active = empresa.status === 'ativa' ? true : false;

    return (
        <>
            <TableRow hover key={empresa.cnpj}>
                <TableCell>{formatarCNPJ(empresa.cnpj)}</TableCell>
                <TableCell>{empresa.nomeFantasia}</TableCell>
                <TableCell>{`${empresa.cidade} - ${empresa.estado}`}</TableCell>
                <TableCell>
                    <Switch disabled checked={!!active} onChange={() => { }} />
                </TableCell>

                {hasPermission(["admin", "admin_empresas", "update_empresas"]) &&
                    <TableCell align='right'>
                        <IconButton
                            aria-label="Editar"
                            onClick={() => handleOpenEditForm(empresa)}
                            disabled={!active}
                            sx={{ filter: !active ? "grayscale(1) brightness(1.1)" : "" }}
                        >
                            {isAdminSempher ? <EditIcon /> : <Visibility color='primary' />}
                        </IconButton>

                        <Tooltip title={empresa.firstKey ? "Gerar Chaves de Integração" : "Gerar novo Par de Chaves"}>
                            <IconButton
                                aria-label="Gerar Chaves de Integração"
                                onClick={() => handleOpenConfirmGenerateTokenDialog(empresa)}
                                disabled={!active}
                                sx={{ filter: !active ? "grayscale(1) brightness(1.1)" : "" }}
                            >
                                {empresa.firstKey ? <AddKeyIcon /> : <ResetKeyIcon />}
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                }
            </TableRow>
        </>
    );
};

export function Integration({ isAdminSempher }) {
    const queryClient = useQueryClient();
    const { exibirAlerta, exibirDialog, handleCloseDialog } = useCommonItems();

    const passwordDialogRef = useRef();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [editFormOpen, setEditFormOpen] = useState(false);
    const [openConfirmGenerateTokenDialog, setOpenConfirmGenerateTokenDialog] = useState(false);
    const [openIntegrationTokenDialog, setOpenIntegrationTokenDialog] = useState(false);

    const queryKey = ['listaEmpresas', page, rowsPerPage];
    const { data: queryEmpresasData, isLoading, isPending } = useQuery({
        queryKey: queryKey,
        queryFn: () => listaEmpresas(null, page * rowsPerPage, rowsPerPage),
        refetchOnWindowFocus: false,
    });

    const empresas = queryEmpresasData?.data?.data || [];
    const isLoadingEmpresas = isLoading || isPending;

    const numTotalItems = queryEmpresasData?.data?.numero_total ?? 0;

    const { data: queryModulosData } = useQuery({
        queryKey: ['listaModulos'],
        queryFn: () => listaModulos(),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        const requiredPermissionsView = ['admin'];
        checkPermissionsAndRedirect(requiredPermissionsView);
    }, []);

    function handleEditIntegration(item) {
        setEditFormOpen(true);
        setSelectedCompany(item);
    }

    const handleOpenConfirmGenerateTokenDialog = (item) => {
        setSelectedCompany(item);
        setOpenConfirmGenerateTokenDialog(true);
    };

    const { mutateAsync: handleGenerateIntegrationToken, isPending: isLoadingUpdateToken } = useMutation({
        mutationFn: async (data) => await generateIntegrationTokenEmpresa(data.id),
        onSuccess: (response) => {
            const { public_key, secret_key } = response?.data;
            if (public_key && secret_key) {
                setSelectedCompany((prevState) => ({ ...prevState, public_key, secret_key }));
                setOpenIntegrationTokenDialog(true);
            }
        },
        onError: (error) => {
            const message = error.response?.data?.message ?? 'Erro ao gerar token';
            exibirAlerta('Erro', message, 'error');
        }
    });

    const handleOpenGenerateTokenDialog = async (item) => {
        try {
            exibirDialog('Gerando token. Aguarde...');
            // endpoint generate Chaves
            await handleGenerateIntegrationToken({ id: item.id });
        } catch (error) {
            console.error('ListOfEquipment ~ error:', error);
        } finally {
            handleCloseDialog();
        }
    };

    const handleOpenPasswordConfirmation = async (item) => {
        setSelectedCompany(item);
        setOpenConfirmGenerateTokenDialog(false);

        try {
            const result = await passwordDialogRef.current.openPasswordConfirmation();
            if (result) {
                handleOpenGenerateTokenDialog(item);
            }
        } catch (error) {
            console.error('Confirmação de senha cancelada ou erro:', error);
        }
    };

    return (
        <>
            <Helmet title="Integrações Externas" defer={false} />

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
                        <Typography variant='span'>Gerenciamento</Typography>
                        <LinkNavegacao to='/gerenciamento/configuracoes'>Configurações</LinkNavegacao>
                        <Typography variant='span'>Integrações Externas</Typography>
                    </Breadcrumbs>

                    <PageTitle
                        icon={<DisconnectedIcon fontSize='large' color='primary' />}
                        title='Integrações Externas'
                        description={isAdminSempher ?
                            'Gerencie as integrações das empresas com outros sistemas.'
                            : 'Visualize as integrações dos seus dados com outros sistemas.'
                        }
                    />
                </Grid>

                <Grid container item xs={12} spacing={1}>

                </Grid>

                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="Empresas">
                            <TableHead>
                                <TableRow>
                                    <TableCell>CNPJ</TableCell>
                                    <TableCell>Nome Fantasia</TableCell>
                                    <TableCell>Cidade/Estado</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoadingEmpresas ?
                                    <SkeletonTableRow
                                        numCells={5}
                                        numRows={rowsPerPage < 20 ? rowsPerPage : 5}
                                    />
                                    : empresas && empresas.length > 0 ?
                                        empresas?.map((empresa) => (
                                            <CompanyRow
                                                key={empresa.id}
                                                empresa={empresa}
                                                handleExibirAlerta={exibirAlerta}
                                                isAdminSempher={isAdminSempher}
                                                handleOpenEditForm={handleEditIntegration}
                                                handleOpenConfirmGenerateTokenDialog={handleOpenConfirmGenerateTokenDialog}
                                            />
                                        ))
                                        : <EmptyTableRow />
                                }
                            </TableBody>
                        </Table>

                        <CustomTablePagination
                            numTotalItems={numTotalItems}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
            </Grid>

            <IntegrationForm
                open={editFormOpen}
                setOpen={(value) => {
                    setEditFormOpen(value);
                    if (!value) {
                        setSelectedCompany(null);
                    }
                }}
                selectedCompany={selectedCompany}
                modulos={queryModulosData?.data?.data || []}
                queryKey={queryKey}
                isAdminSempher={isAdminSempher}
            />

            <ConfirmGenerateIntegrationTokenDialog
                open={openConfirmGenerateTokenDialog}
                selectedCompany={selectedCompany}
                goAction={() => handleOpenPasswordConfirmation(selectedCompany)}
                handleClose={() => {
                    setOpenConfirmGenerateTokenDialog(false);
                    setSelectedCompany(null);
                }}
                isLoading={isLoadingUpdateToken}
            />

            <GenerateIntegrationTokenDialog
                open={openIntegrationTokenDialog}
                selectedCompany={selectedCompany}
                handleClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        setSelectedCompany(null);
                        setOpenIntegrationTokenDialog(false);
                        // invalidate query to refetch data empresas
                        queryClient.invalidateQueries('listaEmpresas');
                    }
                }}
            />

            <PasswordConfirmationDialog dialogRef={passwordDialogRef} />
        </>
    );
}