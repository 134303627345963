import { Breadcrumbs, Divider, Grid, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PageTitle from '../../../common/PageTitle';
import { SettingsIcon } from '../../../common/Icons';
import LinkNavegacao from '../../../common/Link';
import CardOption from '../../../common/CardOption';

function Settings() {
    return (<>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}

        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
                    <Typography variant='span'>Gerenciamento</Typography>
                    <Typography variant='span'>Configurações</Typography>
                </Breadcrumbs>
                <PageTitle icon={<SettingsIcon fontSize='large' />} title={'Configurações'} />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h5">Geral</Typography>
                <Divider />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption
                    title="Configuração de Termos para Documentos de EPI"
                    description="Configure as informações dos seus documentos."
                    url='configuracao-empresa'
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h5">Integrações</Typography>
                <Divider />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption
                    title="Integrações"
                    description="Configure a integração dos seus dados com sistemas externos."
                    url='integracao'
                />
            </Grid>

            {/* <Grid item xs={12}>
                <Typography variant="h5">Exportações</Typography>
                <Divider />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption
                    title="Exportações"
                    description="Exporte seus dados para alimentar outros sistemas."
                    url='exportacao'
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption
                    title="Configurações de Exportação"
                    description="Configure o formato de exportação dos dados de sua preferência."
                    url='configuracoes-exportacao'
                />
            </Grid> */}
        </Grid>
    </>);
}

export default Settings;