import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  TextField
} from '@mui/material';

export function AutocompleteProducts({
  name,
  control,
  rules,
  errors,
  onChange,
  produtos,
  isLoading,
  disabled,
  helperText,
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, onChange: controllerOnChange, ...field } }) => (
        <Autocomplete
          {...field}
          groupBy={(option) => (option.ca ? `CA: ${option.ca}` : "Sem CA")}
          options={
            produtos.length > 0
              ? [
                { id: "all", ca: "", nome: "Todos os produtos", status: 1 },
                ...produtos.flatMap((produto) => [
                  { ...produto, tipo: "pai" },
                  ...(produto.filhos || [])
                    // .filter((filho) => filho.status === 1)
                    // .filter((filho) => filho.nome.split(produto.nome)[1].trim() !== "ÚNICO") // Filtra as variações do tipo "ÚNICO"
                    .map((filho) => ({
                      ...filho,
                      tipo: "filho",
                      parentName: produto.nome,
                      variationName: filho.nome.split(produto.nome)[1] || "padrão",
                      parentCa: produto.ca,
                    })),
                ]),
              ]
              : []
          }
          getOptionLabel={(option) => {
            const caDisplay = option.ca ? `CA: ${option.ca} - ` : "";
            return option.tipo === "filho"
              ? `${caDisplay}${option.parentName} - Variação: ${option.variationName}`
              : `${caDisplay}${option.nome}`;
          }}
          renderOption={(props, option) => {
            const caDisplay = option.ca ? `${option.ca} - ` : "";
            return (
              <MenuItem {...props} key={option.id} disabled={option.status === 0}>
                {option.tipo === "filho"
                  ? `↳ Variação: ${option.variationName}`
                  : `${caDisplay} ${option.nome}`
                }
                {option.status === 0 ? " - (Inativo)" : ""}
              </MenuItem>
            );
          }}
          onChange={(event, newValue) => onChange(event, newValue)}
          loading={isLoading}
          disabled={disabled}
          loadingText="Carregando..."
          renderInput={(params) => (
            <TextField
              {...params}
              label="Produto"
              error={!!errors[name]}
              helperText={helperText}
              size="small"
              disabled={disabled}
              InputProps={{
                ...params.InputProps,
                style: { backgroundColor: "#fff" },
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={16} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          noOptionsText="Nenhum disponível"
        />
      )}
    />
  );
};