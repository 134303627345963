import { useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import 'dayjs/locale/pt-br';
import { Box, IconButton, Typography, MenuItem, Menu, Button, Checkbox, Divider, Skeleton, Stack, Tooltip, CircularProgress, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import { hasPermission } from '../../configs/functions';
import { atualizaAlerta } from '../screens/epis/alerts/configs/functions';
import { useCommonItems } from '../../contexts/CommonItensProvider';
import { NotificationsOffTwoTone } from '@mui/icons-material';
import ConfirmDialog from './ConfirmDialog';
import { BoxImportantIcon, Checkmark2Icon } from './Icons';
import { formatKey, renderValue } from '../screens/epis/alerts/utils/formatDetailsAlert';
import { useQueryClient } from '@tanstack/react-query';
import { formatDateTime } from '../../utils/format-date';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.locale('pt-br');

function getStyles(bgColor, applyOpacity = true) {
    return {
        width: 10,
        height: 10,
        borderRadius: "50%",
        border: '1px solid #ccc',
        backgroundColor: bgColor ? (applyOpacity ? `${bgColor}80` : bgColor) : 'transparent',
    };
}

const AlertItem = ({ option, handleToggleStatus, isSmallScreen }) => {
    const isPending = option.status;

    return (
        <MenuItem key={option.id} sx={{ borderBottom: '1px solid #ccc', cursor: 'default' }}>
            <Stack direction='column' sx={{ width: '100%' }}>
                <Tooltip
                    title={
                        option.json_info &&
                        <Box sx={{ backgroundColor: grey[50], padding: 1, borderRadius: 1, color: grey[900] }}>
                            <Stack spacing={1}>
                                {Object.entries(JSON.parse(option.json_info)).map(([key, value], index) => (
                                    <Box key={`${key}-${index}`}>
                                        <Typography variant='body2'>
                                            <strong>{formatKey(key)}:</strong> {renderValue(value)}
                                        </Typography>
                                    </Box>
                                ))}
                            </Stack>
                        </Box>
                    }
                    placement={isSmallScreen ? 'bottom' : 'left'}
                >
                    <Stack direction='row' spacing={2} alignItems={'center'} justifyContent={'space-between'} flexWrap={'nowrap'}>
                        <Box sx={getStyles(option.hexadecimalColor)}></Box>

                        <Stack direction='column' sx={{ flex: 1, maxWidth: '280px' }}>
                            <Typography
                                variant='subtitle1'
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {option.alarme.tipo}
                            </Typography>

                            <Typography variant='caption' sx={{ whiteSpace: 'pre-line' }}>
                                {option.empresa.nome}
                            </Typography>

                            <Typography variant='caption'>
                                {formatDateTime(option.createdAt)} &#8226; {dayjs(option.createdAt, 'DD-MM-YYYY HH:mm:ss').fromNow()}
                            </Typography>
                        </Stack>

                        {option.alarme.tipo === "Descumprimento de retirada de EPI" ? (
                            <Tooltip title={isPending ? "Retirada Pendente" : "Retirada Realizada"}>
                                <IconButton size='small'>
                                    {isPending ? <BoxImportantIcon sx={{ margin: 0.2 }} /> : <Checkmark2Icon sx={{ margin: 0.2 }} />}
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={isPending ? "Marcar como Resolvido" : "Reabrir Alerta"}>
                                <Box size='small' sx={{ backgroundColor: "#fff", marginLeft: 2, padding: '5px', borderRadius: '50%', '&:hover': { backgroundColor: "#dddddd", minWidth: 20, } }}>
                                    <Checkbox
                                        // se não está pendente (status = 0), então está resolvido (checado)
                                        checked={!isPending}
                                        onClick={(e) => {
                                            handleToggleStatus(option)
                                            e.stopPropagation()
                                        }}
                                        disabled={!hasPermission(["admin", "admin_alerta", "update_alerta"])}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        sx={{ padding: 0.2 }}
                                    />
                                </Box>
                            </Tooltip>
                        )}
                    </Stack>
                </Tooltip>
            </Stack>
        </MenuItem>
    )
}

export function AlertsMenu({ alerts, unreadAlerts, totalUnreadAlerts, limitUnreadAlerts, anchorEl, handleCloseMenu, queryKeyListAlertsMenu, queryKeyListUnreadAlerts, isLoading }) {
    const queryClient = useQueryClient();
    const { exibirAlerta } = useCommonItems();
    const [isUpdating, setIsUpdating] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const handleToggleStatus = async (alert) => {
        const newStatus = !alert.status;

        setIsUpdating(true);
        try {
            const res = await atualizaAlerta({ id: alert.id, status: newStatus });

            if (res.status === 200) {
                const updatedAlerts = alerts.map(item => {
                    if (item.id === parseInt(alert.id)) {
                        item.status = newStatus;
                    }

                    return item;
                });

                // Ensure alerts do not contain unread alerts
                // updatedAlerts = updatedAlerts.filter(item => !item.status);
                // setAlerts(updatedAlerts);

                // atualizar alerts pelo queryKey
                // buscar em cache os dados da requisição e atualizar
                const cached = queryClient.getQueryData(queryKeyListAlertsMenu);
                const cachedData = cached.data;

                if (cached) {
                    queryClient.setQueryData(queryKeyListAlertsMenu, {
                        ...cached,
                        data: {
                            ...cachedData,
                            data: updatedAlerts
                        }
                    });
                }

                // verificar se o unreadAlerts contem o alerta
                if (unreadAlerts.some(item => item.id === alert.id)) {
                    // remove from unreadAlerts
                    const newUnreadAlerts = unreadAlerts.filter(item => item.id !== alert.id);
                    // setUnreadAlerts(newUnreadAlerts);

                    // atualizar unreadAlerts pelo queryKey
                    const cachedUnread = queryClient.getQueryData(queryKeyListUnreadAlerts);
                    const cachedDataUnread = cachedUnread.data;

                    if (cachedUnread) {
                        queryClient.setQueryData(queryKeyListUnreadAlerts, {
                            ...cachedUnread,
                            data: {
                                ...cachedDataUnread,
                                data: newUnreadAlerts
                            }
                        });
                    }
                }
                // se o novo status for falso, adicionar ao unreadAlerts
                else if (newStatus) {
                    // setUnreadAlerts([...unreadAlerts, alert]);

                    // atualizar unreadAlerts pelo queryKey
                    const cachedUnread = queryClient.getQueryData(queryKeyListUnreadAlerts);
                    const cachedDataUnread = cachedUnread.data;

                    if (cachedUnread) {
                        const updatedData = [...cachedDataUnread.data, alert];

                        queryClient.setQueryData(queryKeyListUnreadAlerts, {
                            ...cachedUnread,
                            data: {
                                ...cachedDataUnread,
                                data: updatedData
                            }
                        });
                    }
                }

                invalidateAlertsListQueries();
            }
        }
        catch (error) {
            console.log('error:', error);
            exibirAlerta('Erro', 'Erro ao alterar status', 'error')
        }
        finally {
            setIsUpdating(false);
        }
    }

    const handleUpdateAllAlerts = async () => {
        if (!unreadAlerts) return;

        setIsUpdating(true);

        // Filter alerts to only include those of the specified type before updating
        const alertsToUpdate = unreadAlerts.filter(alert => alert.alarme.tipo !== "Descumprimento de retirada de EPI");

        // Promise.all para esperar todas as requisicoes serem resolvidas
        const updatedAlerts = await Promise.all(alertsToUpdate.map(async (item) => {
            await atualizaAlerta({ id: item.id, status: 0 });
            return { ...item, status: 0 };
        }));

        // atualizar alerts
        const newAlerts = alerts.map(item => {
            const updatedItem = updatedAlerts.find(alert => alert.id === item.id);
            if (updatedItem) {
                return updatedItem;
            }
            return item;
        });
        // setAlerts(newAlerts);

        // atualizar alerts pelo queryKey
        const cached = queryClient.getQueryData(queryKeyListAlertsMenu);

        if (cached) {
            queryClient.setQueryData(queryKeyListAlertsMenu, {
                ...cached,
                data: {
                    ...cached.data,
                    data: newAlerts
                }
            });
        }

        // Remove the updatedAlerts from unreadAlerts
        const remainingUnreadAlerts = unreadAlerts.filter(alert => !updatedAlerts.some(updatedAlert => updatedAlert.id === alert.id));
        // setUnreadAlerts(remainingUnreadAlerts);

        // atualizar unreadAlerts pelo queryKey
        const cachedUnread = queryClient.getQueryData(queryKeyListUnreadAlerts);

        if (cachedUnread) {
            queryClient.setQueryData(queryKeyListUnreadAlerts, {
                ...cachedUnread,
                data: {
                    ...cachedUnread.data,
                    data: remainingUnreadAlerts
                }
            });
        }

        setIsUpdating(false);
        setOpenDialog(false);
        invalidateAlertsListQueries();
    }

    function invalidateAlertsListQueries() {
        const isListAlertsPage = window.location.pathname.includes('/epis/alertas/lista_alertas');
        if (isListAlertsPage) {
            // Invalidate every query with a key that starts with `listOfAlerts`
            queryClient.invalidateQueries({ queryKey: ['listOfAlerts'] })
        }
    }

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    return (
        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            slotProps={{
                paper: {
                    style: {
                        position: 'relative',
                        maxHeight: isSmallScreen ? '72vh' : 430,
                        width: isSmallScreen ? '100%' : '24rem',
                        marginTop: isSmallScreen ? '22vh' : '0',
                    },
                },
            }}
            anchorOrigin={{
                vertical: isSmallScreen ? 'top' : 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: isSmallScreen ? 'top' : 'top',
                horizontal: 'right',
            }}
        >
            <Stack direction='row' justifyContent='space-between' alignItems='center' width={1} p={1} px={2} sx={{ borderBottom: '1px solid #ccc' }}>
                <Typography variant='body1' sx={{ fontWeight: '700', color: grey[700] }}>Alertas</Typography>

                {(unreadAlerts.length >= 1 && hasPermission(["admin", "admin_alerta", "update_alerta"])) &&
                    <Button
                        variant='text'
                        color='primary'
                        size='small'
                        onClick={() => setOpenDialog(true)}
                        disabled={isUpdating && unreadAlerts.length === 0}
                    >
                        {totalUnreadAlerts > limitUnreadAlerts
                            ?
                            <Typography variant='caption'>
                                Marcar <strong>{limitUnreadAlerts}</strong> itens como resolvidos
                            </Typography>
                            :
                            <Typography variant='caption'>
                                Marcar <strong>todos</strong> como resolvidos
                            </Typography>
                        }
                    </Button>
                }
            </Stack>

            <ConfirmDialog
                title='Atualizar Alertas'
                description={
                    totalUnreadAlerts > limitUnreadAlerts
                        ? `Tem certeza que marcar estes ${limitUnreadAlerts} alertas como resolvidos?`
                        : 'Tem certeza que marcar todos como resolvidos'
                }
                goAction={handleUpdateAllAlerts}
                handleClose={setOpenDialog}
                state={openDialog}
                buttonColor='primary'
            />

            {isLoading
                ? <Stack direction='column' spacing={0.5} py={0.5}>
                    <Skeleton variant="rectangular" width="100%" height={60} />
                    <Divider />
                    <Skeleton variant="rectangular" width="100%" height={60} />
                    <Divider />
                </Stack>
                : alerts.length === 0 && unreadAlerts.length === 0
                    ?
                    <Stack direction='row' justifyContent='center' alignItems='center' spacing={1} sx={{ borderBottom: '1px solid #ccc', py: 1, px: 2 }}>
                        <NotificationsOffTwoTone color='disabled' />
                        <Typography variant='subtitle1' color={grey[800]}>Nenhum alerta recente.</Typography>
                    </Stack>
                    : <Box>
                        {unreadAlerts.length > 0 && (
                            <Box>
                                <Stack sx={{ borderBottom: '1px solid #ccc', backgroundColor: grey[200], py: 1, px: 2 }}>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography variant='body1' sx={{ fontWeight: '700', color: grey[700] }}>Não Resolvidos</Typography>
                                        <Box sx={getStyles("#ff0000", false)}></Box>
                                    </Stack>
                                </Stack>

                                {unreadAlerts.map((option) =>
                                    <AlertItem
                                        key={option.id}
                                        option={option}
                                        handleToggleStatus={handleToggleStatus}
                                        isSmallScreen={isSmallScreen}
                                    />
                                )}
                            </Box>
                        )}

                        {alerts.length > 0 &&
                            <Box>
                                <Stack sx={{ borderBottom: '1px solid #ccc', backgroundColor: grey[200], py: 1, px: 2 }}>
                                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                        <Typography variant='body1' sx={{ fontWeight: '700', color: grey[700] }}>Recentes</Typography>
                                        <Typography variant='body2' sx={{ fontWeight: '500', color: grey[600] }}>(Últimos {alerts.length})</Typography>
                                    </Stack>
                                </Stack>

                                {alerts.map((option) =>
                                    <AlertItem
                                        key={option.id}
                                        option={option}
                                        handleToggleStatus={handleToggleStatus}
                                        isSmallScreen={isSmallScreen}
                                    />
                                )}
                            </Box>
                        }
                    </Box>
            }

            <Stack direction='row' justifyContent='center' alignItems='center' width={1} p={1} px={2} pb={0}>
                <Link to={"/epis/alertas/lista_alertas"}>
                    <Button onClick={handleCloseMenu} variant='contained'>
                        <Typography variant='caption'>Ver todos</Typography>
                    </Button>
                </Link>
            </Stack>

            {/* Loading Overlay */}
            {isUpdating && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: 430,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1300,
                    }}
                >
                    <CircularProgress size={30} color='primary' />
                </Box>
            )}
        </Menu>
    );
}
