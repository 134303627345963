import { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    Paper,
    TableBody,
    IconButton,
    Switch,
    Stack,
    Avatar,
} from '@mui/material';
import { Home, Add, Visibility } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import LinkNavegacao from '../../../common/Link';
import PageTitle from '../../../common/PageTitle';
import { atualizaProduto, deletaProduto, listaProdutos } from './configs/functions';
import { EditIcon, ProtectiveGearAIcon, TrashIcon } from '../../../common/Icons';
import { checkPermissionsAndRedirect, getCategoryIconByName, hasPermission } from '../../../../configs/functions';
import { ProductForm } from './ProductForm/index';
import { ProductSkeletonTableRow } from './components/ProductSkeletonTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { useAppContext } from '../../../../contexts/AppContext';
import { listaEmpresas } from '../../management/companies/configs/functions';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { useSearchParams } from 'react-router-dom';
import { FiltersButton } from '../../../common/FiltersButton';
import { Pagination } from '../../../common/Pagination';
import { ProductsTableFilter } from './components/ProductsTableFilter';
import { useModuleContext } from '../../../../contexts/ModuleProvider';
import { CustomToolbarExportTable } from '../../../common/CustomToolbarExportTable';
import { extractErrorMessage } from '../../../../utils/extract-error-message';

const ProductRow = ({ data, actionsAllowed, handleOpenEditForm, handleUpdateStatus, handleOpenDeleteDialog }) => {
    const [imgError, setImgError] = useState(false);

    useEffect(() => {
        if (data.img) {
            // monitorar mudança de estado da imagem
            // se a imagem for carregada com sucesso, reseta o erro
            setImgError(false);
        }
    }, [data.img]);

    return (
        <TableRow key={data.id}>
            <TableCell>{data.ca ? data.ca : 'N/A'}</TableCell>
            <TableCell>
                <Stack direction="row" spacing={4} alignItems="center" gap={2}>
                    <Avatar
                        sx={{
                            bgcolor: grey[100], color: grey[600], maxWidth: 38, maxHeight: 38
                        }}
                        onDragStart={(e) => e.preventDefault()}
                    >
                        {data.img && !imgError ? (
                            <img
                                src={data.img}
                                alt={data.nome}
                                style={{ width: '100%' }}
                                onError={() => setImgError(true)}
                            />
                        ) : getCategoryIconByName(data.categoria.icon_name)}
                    </Avatar>

                    {data.nome}
                </Stack>
            </TableCell>
            <TableCell>{(data.categoria.nome)}</TableCell>
            <TableCell><Typography noWrap sx={{ display: 'block', width: '25rem' }}>{data.observacao}</Typography></TableCell>
            <TableCell align='right'>
                <Switch
                    checked={!!data.status}
                    onChange={handleUpdateStatus}
                    disabled={!hasPermission(["admin", "admin_produtos", "update_produtos"]) || !actionsAllowed}
                />
            </TableCell>
            <TableCell sx={{ minWidth: 110 }} align='right'>
                {hasPermission(["admin", "admin_produtos", "update_produtos"]) && (
                    <IconButton
                        aria-label="Editar"
                        onClick={() => handleOpenEditForm(data)}
                    >
                        {actionsAllowed ? <EditIcon /> : <Visibility color='primary' />}
                    </IconButton>
                )}

                {hasPermission(["admin", "admin_produtos", "delete_produtos"]) && actionsAllowed && (
                    <IconButton
                        aria-label="Excluir"
                        onClick={() => handleOpenDeleteDialog()}
                    >
                        <TrashIcon />
                    </IconButton>
                )}
            </TableCell>
        </TableRow>
    )
}

function ListOfProducts() {
    const queryClient = useQueryClient();

    const { exibirAlerta } = useCommonItems();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();
    const { verifyIntegrationMode, getEmpresasFilteredByModule } = useModuleContext();

    const [searchParams, setSearchParams] = useSearchParams();

    const [openForm, setOpenForm] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [moduleActionsAllowed, setModuleActionsAllowed] = useState(false);

    // estados para controle de paginacao
    const searchParamPage = searchParams.get('page');
    const searchParamPerPage = searchParams.get('perPage');

    // estados para controle de paginacao
    const page = searchParamPage ? (parseInt(searchParamPage) - 1) : 0;
    const rowsPerPage = searchParamPerPage ? parseInt(searchParamPerPage) : 10;

    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [activeFilters, setActiveFilters] = useState(0);

    const columns = useMemo(() => [
        { field: 'ca', headerName: 'CA' },
        { field: 'nome', headerName: 'Produto' },
        { field: 'cod_externo', headerName: 'Cód. Externo' },
        { field: 'categoria.nome', headerName: 'Categoria', valueGetter: (params) => params.row.categoria.nome },
        { field: 'indicacao', headerName: 'Indicação' },
        { field: 'status', headerName: 'Status', valueGetter: (params) => params.row.status === 1 ? 'Ativo' : 'Inativo' },
    ], []);

    const { data: empresasData, error: listaEmpresasError } = useQuery({
        queryKey: ['listaEmpresas'],
        queryFn: async () => {
            const response = await listaEmpresas();
            const empresasData = response.data.data;

            const selectedEmpresaId = getEmpresaIdSession() || empresasData[0]?.id;

            setEmpresaIdSession(selectedEmpresaId);

            setSearchParams((state) => {
                state.set('id_empresa', String(selectedEmpresaId));
                state.set('page', String(page + 1));
                state.set('perPage', String(rowsPerPage));

                return state;
            });
            return response;
        },
        refetchOnWindowFocus: false,
    });

    const queryFilters = {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        ca: searchParams.get('ca'),
        nome: searchParams.get('nome'),
        id_empresa: searchParams.get('id_empresa'),
    };

    const queryKeyListProducts = ['listOfProducts', queryFilters];
    const { data: productsData, isLoading, isPending, error: listOfProductsError } = useQuery({
        queryKey: queryKeyListProducts,
        queryFn: () => listaProdutos(queryFilters),
        enabled: !!searchParamPage && !!searchParamPerPage,
    });

    const produtos = productsData?.data?.data ?? [];
    const numTotalItems = productsData?.data?.numero_total ?? 0;
    const isLoadingList = isLoading || isPending;

    const { mutateAsync: handleUpdateStatusFn } = useMutation({
        mutationFn: (data) => atualizaProduto(data.id, data.dataToUpdate),
        onSuccess: (response, variables, context) => {
            const newStatus = variables.dataToUpdate.status;

            const message = newStatus ? 'Produto ativado com sucesso.' : 'Produto inativado com sucesso.';
            exibirAlerta('Sucesso', message, 'success');

            queryClient.invalidateQueries({ queryKey: queryKeyListProducts });
            // need return updated product to update cached data
            // updateCachedData(updatedProduct)
        },
        onError: (error) => {
            const message = error.response?.data?.message ?? 'Erro ao executar operação';
            exibirAlerta('Erro', message, 'error');
        }
    });

    const { mutateAsync: handleDelete } = useMutation({
        mutationFn: deletaProduto,
        onSuccess: (response, id) => {
            const message = response.data.message ?? 'Produto inativado com sucesso';
            exibirAlerta('Sucesso', message, 'success');
            queryClient.invalidateQueries({ queryKey: queryKeyListProducts });
        },
        onError: (error) => {
            const message = error.response?.data?.message ?? 'Erro ao executar operação';
            exibirAlerta('Erro', message, 'error');
        },
        onSettled: () => {
            setSelectedProduct(null);
            setOpenDeleteDialog(false);
        },
    });

    const handleOpenDeleteDialog = (item) => {
        setSelectedProduct(item);
        setOpenDeleteDialog(true);
    }

    function handleEditProduct(item) {
        setSelectedProduct(item);
        setOpenForm(true);
    }

    useEffect(() => {
        if (openForm) return;
        setSelectedProduct(null);

        const queryFilters = { ...Object.fromEntries(searchParams) }

        const tableFilters = { ...queryFilters };
        delete tableFilters.page;
        delete tableFilters.perPage;

        const activeFilters = Object.keys(tableFilters).length;

        setActiveFilters(activeFilters);
        setIsOpenFilters(activeFilters > 0);

        const empresaHasActionsAllowed = verifyIntegrationMode("Produto", searchParams.get('id_empresa'));
        setModuleActionsAllowed(empresaHasActionsAllowed);
    }, [openForm, searchParams, verifyIntegrationMode]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_produtos", "list_produtos"];
        checkPermissionsAndRedirect(requiredPermissionsView);
    }, []);

    useEffect(() => {
        let errorMessage = "";

        if (listOfProductsError) {
            errorMessage = extractErrorMessage(listOfProductsError, 'Erro ao carregar a lista de produtos');
        } else if (listaEmpresasError) {
            errorMessage = extractErrorMessage(listaEmpresasError, 'Erro ao carregar a lista de empresas');
        }

        errorMessage && exibirAlerta('Ops! Ocorreu um erro', errorMessage, 'error');
    }, [listOfProductsError, listaEmpresasError, exibirAlerta]);

    return (<>
        <Helmet title='Produtos' defer={false} />

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="end"
            spacing={3}
        >
            <Grid item xs={12} md={9}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><Home fontSize='small' /></LinkNavegacao >
                    <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao >
                    <LinkNavegacao to='/epis/produtos'>Produtos</LinkNavegacao >
                    <Typography variant='span'>Produtos Cadastrados</Typography>
                </Breadcrumbs>

                <PageTitle
                    icon={<ProtectiveGearAIcon fontSize='large' />}
                    title='Produtos Cadastrados'
                    description='Administre os produtos disponíveis na(s) sua(s) empresa(s).'
                />
            </Grid>

            <Grid item xs={12}>
                <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} justifyContent={'space-between'}>
                    <FiltersButton
                        isOpenFilters={isOpenFilters}
                        setIsOpenFilters={setIsOpenFilters}
                        activeFilters={activeFilters}
                    />

                    {hasPermission(["admin", "admin_produtos", "create_produtos"]) &&
                        <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                onClick={() => setOpenForm(true)}
                            >
                                Cadastrar novo produto
                            </Button>
                        </Typography>
                    }
                </Stack>
            </Grid>

            <ProductsTableFilter
                isOpenFilters={isOpenFilters}
                isLoadingList={isLoadingList}
                empresas={empresasData?.data?.data || []}
            />

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <CustomToolbarExportTable
                        rows={produtos}
                        columns={columns}
                        reportName="relatorio_produtos"
                        reportPdfTitle="RELATÓRIO DE PRODUTOS"
                        getDataWithoutPaginate={() => {
                            const { limit, offset, ...data } = queryFilters;
                            return listaProdutos(data);
                        }}
                        getSelectedEmpresa={() => empresasData.data.data.find(empresa => empresa.id === Number(searchParams.get('id_empresa')))}
                    />

                    <Table aria-label="Produtos">
                        <TableHead>
                            <TableRow>
                                <TableCell>CA</TableCell>
                                <TableCell>Produto</TableCell>
                                <TableCell>Categoria</TableCell>
                                <TableCell>Observação</TableCell>
                                <TableCell align='right'>Status</TableCell>
                                <TableCell align='right'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoadingList ? (
                                <ProductSkeletonTableRow
                                    numCells={5}
                                    numRows={rowsPerPage < 20 ? rowsPerPage : 5}
                                />
                            ) : (
                                produtos && produtos.length > 0 ? (
                                    produtos?.map((item) => (
                                        <ProductRow
                                            key={item.id}
                                            data={item}
                                            handleUpdateStatus={() =>
                                                handleUpdateStatusFn({ id: item.id, dataToUpdate: { status: !item.status } })
                                            }
                                            handleOpenEditForm={handleEditProduct}
                                            handleOpenDeleteDialog={() => handleOpenDeleteDialog(item)}
                                            actionsAllowed={moduleActionsAllowed}
                                        />
                                    ))
                                ) : <EmptyTableRow infoType='produto' />
                            )}
                        </TableBody>
                    </Table>

                    <Pagination
                        numTotalItems={numTotalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                    />
                </TableContainer>
            </Grid>
        </Grid>

        <ProductForm
            open={openForm}
            setOpen={setOpenForm}
            selectedProduct={selectedProduct}
            empresas={getEmpresasFilteredByModule('Produto', empresasData?.data?.data ?? [], !selectedProduct)}
            queryKey={queryKeyListProducts}
            actionsAllowed={moduleActionsAllowed}
        />

        <ConfirmDialog
            title={`Excluir Produto ${selectedProduct?.nome}`}
            description="Tem certeza que deseja excluir este produto?"
            state={openDeleteDialog}
            goAction={() => handleDelete(selectedProduct.id)}
            handleClose={() => {
                setOpenDeleteDialog(false);
                setSelectedProduct(null);
            }}
        />
    </>);
}

export default ListOfProducts;