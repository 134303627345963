import axios from "axios";
import { urlDashboard } from "../../../../../constants/endpoints";

const makeRequest = (endpoint, companiesIds = null, period = null, year) => {
  const normalizedCompanies = Array.isArray(companiesIds) ? companiesIds : [companiesIds];

  if (!normalizedCompanies.length) {
    return Promise.resolve({ data: [] });
  }

  const params = {
    companies: normalizedCompanies,
    ...(period && { period }),
    ...(year && { years: [year] })
  };

  return axios.get(`${urlDashboard}/${endpoint}`, { params });
}

export const getConsumoEpiPorCentroCusto = (companiesIds, period = 30) => makeRequest('consumoEPI', companiesIds, period);

export const getRetiradasRequisicoes = (companiesIds, period = 90) => makeRequest('retiradasRequisicoes', companiesIds, period);

export const getRequisicoesArea = (companiesIds, year = 2025) => makeRequest('requisicoesArea', companiesIds, null, year);

export const getConsumoEpiFunc = (companiesIds) => makeRequest('consumoEpiFunc', companiesIds);

export const getTotalEpisVencidos = (companiesIds) => makeRequest('totalEpisVencidos', companiesIds);

export const getTotalCAsVencidos = (companiesIds) => makeRequest('caVencidos', companiesIds);

export const getTotalCAsVencer = (companiesIds) => makeRequest('vencimentoPeriodoCA', companiesIds);

export const getTotalEquipEstoqueBaixo = (companiesIds) => makeRequest('equipEstoqueBaixo', companiesIds);

export const getTotalEquipDesconectados = (companiesIds) => makeRequest('equipDesconectados', companiesIds);

export const getProductsRanking = (companiesIds) => makeRequest('rankingProdutos', companiesIds);