import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  Collapse,
  Card,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import { useAppContext } from '../../../../../contexts/AppContext';
import { TableFilterButtons } from '../../../../common/TableFilterButtons';
import { maskCPF, getFormErrorMessage, validateCPF } from '../../../../../configs/functions';

export function CollaboratorsTableFilter({ isOpenFilters, isLoadingList, empresas }) {
  const { setEmpresaIdSession } = useAppContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const nome = searchParams.get('nome');
  const cpf = searchParams.get('cpf');
  const matricula = searchParams.get('matricula');
  const id_empresa = searchParams.get('id_empresa');
  const perPage = searchParams.get('perPage');

  const {
    control,
    reset,
    setValue,
    clearErrors,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      nome: nome ? nome : matricula ? matricula : '',
      cpf: cpf ?? '',
      matricula: matricula ?? '',
      id_empresa: id_empresa ?? '',
    },
  });

  function handleSubmitFilter(data) {
    for (const key in data) {
      // remove campos vazios
      if (data[key] === '') {
        delete data[key];
      }
    }

    // if nome is only number, then it's matricula
    if (data.nome && !isNaN(data.nome)) {
      data.matricula = data.nome;
      delete data.nome;
    } else {
      delete data.matricula;
    }

    const queryFilters = {
      ...data,
      page: '1',
      perPage: perPage ?? '10'
    }

    if (data.cpf) {
      // remove all non-numeric characters from cpf
      queryFilters.cpf = data.cpf.replace(/\D/g, '');
    }

    // fill url params with filters
    setSearchParams(queryFilters);

    if (data.id_empresa) {
      setEmpresaIdSession(data.id_empresa);
    }
  }

  useEffect(() => {
    reset({
      nome: nome ? nome : matricula ? matricula : '',
      cpf: cpf ? maskCPF(cpf) : '',
      matricula: matricula ?? '',
      id_empresa: id_empresa ?? '',
    });
  }, [id_empresa, nome, cpf, matricula, reset])

  return (
    <Grid item xs={12} component="form" onSubmit={handleSubmit(handleSubmitFilter)}>
      <Collapse in={isOpenFilters}>
        <Card variant="outlined" sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Controller
                name="id_empresa"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    select
                    label="Empresa"
                    fullWidth
                    size="small"
                    {...field}
                  >
                    {empresas.length > 0 && empresas.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nomeFantasia}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="nome"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="Nome ou Matrícula do Colaborador"
                    fullWidth
                    size="small"
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name='cpf'
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <TextField
                    {...field}
                    label='CPF'
                    onChange={(e) => {
                      setValue('cpf', maskCPF(e.target.value), { shouldDirty: true })
                      clearErrors('cpf');
                    }}
                    onBlur={(e) => {
                      if (!e.target.value || validateCPF(e.target.value)) {
                        clearErrors("cpf")
                      } else {
                        setError("cpf", { type: "invalid", message: "CPF inválido" })
                      }
                    }}
                    error={!!errors.cpf}
                    helperText={getFormErrorMessage(errors, 'cpf')}
                    fullWidth
                    size="small"
                    autoComplete='off'
                    inputProps={{ form: { autocomplete: 'off' } }}
                  />
                )}
              />
            </Grid>

            <TableFilterButtons
              isLoading={isLoadingList}
              handleClearFilters={() => {
                setSearchParams((state) => {
                  state.delete('nome')
                  state.delete('cpf')
                  state.delete('matricula')
                  // state.delete('id_empresa')
                  state.set('page', '1')

                  return state
                })

                reset({
                  nome: '',
                  cpf: '',
                  matricula: '',
                  id_empresa: id_empresa ?? '',
                });
              }}
            />
          </Grid>
        </Card>
      </Collapse>
    </Grid>
  );
}
