import { useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    Paper,
    TableBody,
    IconButton,
    Switch,
    Stack,
} from '@mui/material';
import { Add, Home as HomeIcon, Visibility } from '@mui/icons-material';
import { EditIcon, TrashIcon, CheckListIcon } from '../../../common/Icons';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { atualizaGHEs, deletaGHEs, listaGHEs } from './config/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import LinkNavegacao from '../../../common/Link';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import PageTitle from '../../../common/PageTitle';
import { Pagination } from '../../../common/Pagination';
import { FiltersButton } from '../../../common/FiltersButton';
import { useAppContext } from '../../../../contexts/AppContext';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { GHETableFilter } from './components/GHETableFilter';
import GHEForm from './GHEForm';
import { useModuleContext } from '../../../../contexts/ModuleProvider';
import { CustomToolbarExportTable } from '../../../common/CustomToolbarExportTable';
import { extractErrorMessage } from '../../../../utils/extract-error-message';

const GheRow = ({ data, actionsAllowed, handleOpenEditForm, handleUpdateStatus, handleOpenDeleteDialog }) => {
    return (
        <TableRow key={data.id}>
            <TableCell>{data.nome}</TableCell>
            <TableCell>{data.cod_externo}</TableCell>
            <TableCell sx={{
                maxWidth: 450,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}>{data.observacao}</TableCell>

            <TableCell>
                <Switch
                    checked={!!data.status}
                    onChange={handleUpdateStatus}
                    disabled={!hasPermission(["admin", "admin_grupos", "update_grupos"]) || !actionsAllowed}
                />
            </TableCell>

            <TableCell align='right' sx={{ minWidth: 110 }}>
                {hasPermission(["admin", "admin_grupos", "update_grupos"]) && (
                    <IconButton
                        aria-label="Editar"
                        onClick={() => handleOpenEditForm(data)}
                    >
                        {actionsAllowed ? <EditIcon /> : <Visibility color='primary' />}
                    </IconButton>
                )}
                {hasPermission(["admin", "admin_grupos", "delete_grupos"]) && actionsAllowed && (
                    <IconButton
                        aria-label="Excluir"
                        onClick={() => handleOpenDeleteDialog()}
                    >
                        <TrashIcon />
                    </IconButton>
                )}
            </TableCell>
        </TableRow>
    )
}

function ListOfGHEs() {
    const queryClient = useQueryClient();

    const { exibirAlerta } = useCommonItems();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();
    const { verifyIntegrationMode } = useModuleContext();

    const [moduleActionsAllowed, setModuleActionsAllowed] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const searchParamPage = searchParams.get('page');
    const searchParamPerPage = searchParams.get('perPage');

    const page = searchParamPage ? (parseInt(searchParamPage) - 1) : 0;
    const rowsPerPage = searchParamPerPage ? parseInt(searchParamPerPage) : 10;

    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [activeFilters, setActiveFilters] = useState(0);

    const [openForm, setOpenForm] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedGHE, setSelectedGHE] = useState(null);

    const columns = useMemo(() => [
        { field: 'nome', headerName: 'Grupo (GHE)' },
        { field: 'cod_externo', headerName: 'Cód. Externo' },
        { field: 'descricao', headerName: 'Descrição' },
        { field: 'observacao', headerName: 'Observação' },
        { field: 'status', headerName: 'Status', valueGetter: (params) => params.row.status === 1 ? 'Ativo' : 'Inativo' },
    ], []);

    const { data: empresasData, error: listaEmpresasError } = useQuery({
        queryKey: ['listaEmpresas'],
        queryFn: async () => {
            const response = await listaEmpresas();
            const empresasData = response.data.data;

            const selectedEmpresaId = getEmpresaIdSession() || empresasData[0]?.id;

            setEmpresaIdSession(selectedEmpresaId);

            setSearchParams((state) => {
                state.set('page', String(page + 1));
                state.set('perPage', String(rowsPerPage));
                state.set('id_empresa', String(selectedEmpresaId));

                return state;
            });
            return response;
        },
        refetchOnWindowFocus: false,
    });

    const queryFilters = {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        nome: searchParams.get('nome'),
        cod_externo: searchParams.get('cod_externo'),
        id_empresa: searchParams.get('id_empresa'),
    };

    const queryKeyListGHEs = ['listOfGHEs', queryFilters];
    const { data: groupsData, isLoading, isPending, error: listOfGHEsError } = useQuery({
        queryKey: queryKeyListGHEs,
        queryFn: () => listaGHEs(queryFilters),
        enabled: !!searchParamPage && !!searchParamPerPage,
    });

    const ghes = groupsData?.data?.data ?? [];
    const numTotalItems = groupsData?.data?.numero_total ?? 0;
    const isLoadingList = isLoading || isPending;

    const { mutateAsync: handleUpdateStatusFn } = useMutation({
        mutationFn: (data) => atualizaGHEs(data.id, data.dataToUpdate),
        onSuccess: (response, variables, context) => {
            const newStatus = variables.dataToUpdate.status;

            const message = newStatus ? 'Grupo ativado com sucesso.' : 'Grupo desativado com sucesso.';
            exibirAlerta('Sucesso', message, 'success');

            queryClient.invalidateQueries({ queryKey: queryKeyListGHEs });
            // need return updated product to update cached data
            // updateCachedData(updatedProduct)
        },
        onError: (error) => {
            const message = error.response?.data?.message ?? 'Erro ao executar operação';
            exibirAlerta('Erro', message, 'error');
        }
    });

    const { mutateAsync: handleDelete } = useMutation({
        mutationFn: deletaGHEs,
        onSuccess: (response, id) => {
            const message = response.data.message ?? 'Grupo excluído com sucesso.';
            exibirAlerta('Sucesso', message, 'success');
            queryClient.invalidateQueries({ queryKey: queryKeyListGHEs });
        },
        onError: (error) => {
            const message = error.response?.data?.message ?? 'Erro ao executar operação';
            exibirAlerta('Erro', message, 'error');
        },
        onSettled: () => {
            setSelectedGHE(null);
            setOpenDeleteDialog(false);
        },
    });

    function handleEditGHE(item) {
        setSelectedGHE(item);
        setOpenForm(true);
    }

    const handleOpenDeleteDialog = (item) => {
        setSelectedGHE(item);
        setOpenDeleteDialog(true);
    }

    useEffect(() => {
        if (openForm) return;
        setSelectedGHE(null);

        const queryFilters = { ...Object.fromEntries(searchParams) }

        const tableFilters = { ...queryFilters };
        delete tableFilters.page;
        delete tableFilters.perPage;

        const activeFilters = Object.keys(tableFilters).length;

        setActiveFilters(activeFilters);
        setIsOpenFilters(activeFilters > 0);

        const empresaHasActionsAllowed = verifyIntegrationMode('Grupo', searchParams.get('id_empresa'));
        setModuleActionsAllowed(empresaHasActionsAllowed);
    }, [openForm, searchParams, verifyIntegrationMode]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_grupos", "list_grupos"];
        checkPermissionsAndRedirect(requiredPermissionsView);
    }, []);

    useEffect(() => {
        let errorMessage = "";

        if (listOfGHEsError) {
            errorMessage = extractErrorMessage(listOfGHEsError, 'Erro ao carregar a GHEs');
        }

        if (listaEmpresasError) {
            errorMessage = extractErrorMessage(listaEmpresasError, 'Erro ao carregar a lista de empresas');
        }

        errorMessage && exibirAlerta('Ops! Ocorreu um erro', errorMessage, 'error');
    }, [listOfGHEsError, listaEmpresasError, exibirAlerta]);

    return (<>
        <Helmet title='Grupos Homogêneos de Exposição' defer={false} />

        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to="/"><HomeIcon fontSize="small" /></LinkNavegacao>
                    <LinkNavegacao to="/epis">Gestão de EPI's</LinkNavegacao>
                    <LinkNavegacao to="/epis/diretrizes">Diretrizes</LinkNavegacao>
                    <Typography variant="span">Grupos Homogêneos de Exposição</Typography>
                </Breadcrumbs>

                <PageTitle
                    icon={<CheckListIcon fontSize='large' />}
                    title={'Grupos Homogêneos de Exposição'}
                    description='Administre as Grupos Homogêneos de Exposição das suas empresas.'
                />
            </Grid>

            <Grid item xs={12}>
                <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} justifyContent='space-between' alignItems='center'>
                    <FiltersButton
                        isOpenFilters={isOpenFilters}
                        setIsOpenFilters={setIsOpenFilters}
                        activeFilters={activeFilters}
                    />

                    {hasPermission(["admin", "admin_grupos", "create_grupos"]) &&
                        <Button
                            variant="contained"
                            startIcon={<Add />}
                            onClick={() => setOpenForm(true)}
                        >
                            Cadastrar novo GHE
                        </Button>
                    }
                </Stack>
            </Grid>

            <GHETableFilter
                isOpenFilters={isOpenFilters}
                isLoadingList={isLoadingList}
                empresas={empresasData?.data?.data || []}
            />

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <CustomToolbarExportTable
                        rows={ghes}
                        columns={columns}
                        reportName="relatorio_grupos_homogeneos_exposicao"
                        reportPdfTitle="RELATÓRIO DE GRUPOS HOMOGÊNEOS DE EXPOSIÇÃO"
                        getDataWithoutPaginate={() => {
                            const { limit, offset, ...data } = queryFilters;
                            return listaGHEs(data);
                        }}
                        getSelectedEmpresa={() => empresasData.data.data.find(empresa => empresa.id === Number(searchParams.get('id_empresa')))}
                    />

                    <Table aria-label="Grupos Homogêneos de Exposição">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>Cód. Externo</TableCell>
                                <TableCell>Observação</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoadingList ? (
                                <SkeletonTableRow numCells={5} numRows={rowsPerPage < 20 ? rowsPerPage : 5} />
                            ) : (
                                ghes && ghes?.length > 0 ? (
                                    ghes.map((item) =>
                                        <GheRow
                                            key={item.id}
                                            data={item}
                                            handleExibirAlerta={exibirAlerta}
                                            handleOpenEditForm={handleEditGHE}
                                            handleUpdateStatus={() =>
                                                handleUpdateStatusFn({ id: item.id, dataToUpdate: { status: !item.status } })
                                            }
                                            handleOpenDeleteDialog={() => handleOpenDeleteDialog(item)}
                                            actionsAllowed={moduleActionsAllowed}
                                        />
                                    )
                                ) : <EmptyTableRow />
                            )}
                        </TableBody>
                    </Table>

                    <Pagination
                        numTotalItems={numTotalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                    />
                </TableContainer>
            </Grid>
        </Grid>

        <GHEForm
            open={openForm}
            setOpen={setOpenForm}
            selectedGHE={selectedGHE}
            queryKey={queryKeyListGHEs}
            // empresas={getEmpresasFilteredByModule('Setor', empresas, !selectedSector)}
            actionsAllowed={moduleActionsAllowed}
        />

        <ConfirmDialog
            title={`Excluir Grupo ${selectedGHE?.nome}`}
            description='Tem certeza que deseja excluir este grupo?'
            state={openDeleteDialog}
            goAction={() => handleDelete(selectedGHE.id)}
            handleClose={() => {
                setOpenDeleteDialog(false);
                setSelectedGHE(null);
            }}
        />
    </>);
}

export default ListOfGHEs;